<template>
    <div ref="categoryOverviewTile" class="flex flex-col bg-light-grey rounded-xl overflow-hidden">
        <div v-if="containsImage(image)" class="relative">
            <PictureElement
                :img="image"
                :breakpoints="BREAKPOINTS"
                img-class="aspect-3/2 object-cover w-full h-full overflow-hidden"
            ></PictureElement>
            <div class="absolute top-0 inset-x-0 h-1/3 bg-gradient-to-b from-black to-transparent opacity-60"></div>
            <div
                v-if="imageGradientBottom !== false"
                class="absolute bottom-0 inset-x-0 h-1/3 bg-gradient-to-t from-black to-transparent opacity-60"
            ></div>
            <div class="absolute inset-0 flex flex-col justify-between p-6 xl:p-8 xl:pb-5">
                <HeadlineComponent
                    :headline="headline"
                    tag="h2"
                    class="text-2xl leading-7 text-white font-medium xl:text-3xl"
                ></HeadlineComponent>
                <TagList :tags="tags" />
            </div>
        </div>
        <div class="flex flex-col gap-4 items-start p-6 h-full xl:gap-6 xl:p-8 xl:pt-6">
            <div v-if="subline" class="text-lg leading-6 font-normal xl:text-2xl">
                {{ subline }}
            </div>
            <hr v-if="subline" class="w-full" />
            <ul v-if="hasNodes(benefits)" class="flex-1 space-y-4">
                <li
                    v-for="benefit in mapNodes(benefits)"
                    :key="benefit['@id']"
                    class="relative flex items-start gap-x-2"
                >
                    <img
                        v-if="benefit.image"
                        class="w-6"
                        :width="getImageWidth(benefit.image) ?? 24"
                        :height="getImageHeight(benefit.image) ?? 24"
                        :src="imagePath(benefit.image)"
                        :alt="getImageAlt(benefit.image)"
                        :title="getImageTitle(benefit.image)"
                    />
                    <div class="flex flex-col">
                        <HeadlineComponent
                            :tag="h3"
                            :text="benefit.headline"
                            class="text-base leading-snug text-dark-blue font-medium"
                        ></HeadlineComponent>

                        <span class="text-base font-normal leading-snug">{{ benefit.description }}</span>
                    </div>
                </li>
            </ul>
            <CallToActionComponent v-if="link?.label" :link="link" class="mt-2" @click="trackPromoClick" />
        </div>
    </div>
</template>
<script setup>
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';
import TagList from '~/templates/components/TagList.vue';
import {
    containsImage,
    getImageHeight,
    getImageWidth,
    hasNodes,
    imagePath,
    mapNodes,
    getImageAlt,
    getImageTitle,
} from '~/utils/helper/magnolia';
import CallToActionComponent from '~/templates/components/cms/CallToActionComponent.vue';
import { DIMENSIONS } from '~/utils/helper/imaging';

const props = defineProps([
    'headline',
    'subline',
    'benefits',
    'image',
    'imageGradientBottom',
    'tags',
    'filterTags',
    'link',
    'metadata',
]);

const categoryOverviewTile = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
    metadata: props?.metadata,
    creativeName: props?.headline?.text,
});

const BREAKPOINTS = {
    start: DIMENSIONS['start']?.ratio['3/2'],
    sm: DIMENSIONS['sm'].ratio['3/2'],
    md: DIMENSIONS['md'].width['3/2'],
    xl: DIMENSIONS['xl'].width['1/2']?.ratio['3/2'],
};

onMounted(() => {
    trackPromoView(categoryOverviewTile);
});
</script>
