<template>
    <div ref="component" class="scrollable-text-image-video relative">
        <div class="md:grid md:grid-cols-2 md:gap-8">
            <EditableArea :content="main" :custom-view="ScrollableTextImageVideoArea" />
            <aside class="absolute top-0 w-full h-full md:relative md:h-auto">
                <div
                    class="sticky-area"
                    :class="[
                        isEditMode || isComponentPreview() ? 'flex flex-col gap-24' : 'sticky top-4 md:top-[50vh]',
                    ]"
                >
                    <div
                        class="mobile-text-overlay absolute inset-x-0 w-full bg-white transition md:hidden"
                        :class="{ hidden: isEditMode }"
                    ></div>

                    <div
                        v-for="(imageTextComponent, imageIndex) in mapNodes(main)"
                        ref="mediaWrapper"
                        :key="`image-${imageIndex}`"
                        class="overflow-hidden safari-overflow-hidden-fix rounded-xl transition-opacity duration-1000"
                        :class="[
                            { 'absolute top-0 inset-x-0 transform md:-translate-y-1/2': !isEditMode },
                            isEditMode || activeMediaIndex === imageIndex
                                ? 'opacity-100'
                                : ' opacity-0 pointer-events-none',
                        ]"
                    >
                        <div v-if="isEditMode" class="editmode-info-box m-4">INDEX: {{ imageIndex }}</div>
                        <VideoComponent
                            v-if="imageTextComponent?.autoPlayVideo"
                            ref="mediaRefs"
                            class="relative w-full"
                            :video="imageTextComponent.video"
                            :autoplay="true"
                            :image="imageTextComponent.image"
                            loop="true"
                            :controls="false"
                            :load="true"
                            :muted="true"
                        ></VideoComponent>
                        <VideoPictureElement
                            v-else
                            ref="mediaRefs"
                            class="relative w-full mx-auto"
                            :class="[contentToTailwindClasses({ aspectRatio: imageTextComponent.ratio })]"
                            :img="imageTextComponent.image"
                            :img-class="`absolute inset-0 w-full h-full object-cover ${
                                imageTextComponent.ratio === 'original' ? 'relative' : 'absolute'
                            }`"
                            :breakpoints="getImagingBreakpoints(imageTextComponent.ratio)"
                            :video="imageTextComponent.video"
                            :show-video-inline="true"
                        >
                        </VideoPictureElement>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</template>
<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { containsImage, mapNodes } from '~/utils/helper/magnolia';
import VideoPictureElement from '~/templates/elements/VideoPictureElement.vue';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { DIMENSIONS } from '~/utils/helper/imaging';
import { isComponentPreview, isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import ScrollableTextImageVideoArea from '~/templates/components/cms/ScrollableTextImageVideoArea.vue';
import VideoComponent from '~/templates/components/cms/VideoComponent.vue';
import { hasVideo } from '~/utils/helper/video';
const { viewPortWidth } = useWindow();

const isEditMode = evaluateEditMode();

const props = defineProps(['main']);

let BREAKPOINTS_SQUARE = {
    start: DIMENSIONS['start'].ratio.square,
    sm: DIMENSIONS['sm'].ratio.square,
    md: DIMENSIONS['md'].width['1/2'].ratio.square,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.square,
    container: DIMENSIONS['container'].width['1/2'].ratio.square,
};
let BREAKPOINTS_TV = {
    start: DIMENSIONS['start'].ratio?.['4/3'],
    sm: DIMENSIONS['sm'].ratio?.['4/3'],
    md: DIMENSIONS['md'].width['1/2'].ratio?.['4/3'],
    xl: DIMENSIONS['xl'].width['1/2'].ratio?.['4/3'],
    container: DIMENSIONS['container'].width['1/2'].ratio?.['4/3'],
};
let BREAKPOINTS_ORIGINAL = {
    start: DIMENSIONS['start'].ratio.original,
    sm: DIMENSIONS['sm'].ratio.original,
    md: DIMENSIONS['md'].width['1/2'].ratio.original,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.original,
    container: DIMENSIONS['container'].width['1/2'].ratio.original,
};
let BREAKPOINTS_VIDEO = {
    start: DIMENSIONS['start'].ratio.video,
    sm: DIMENSIONS['sm'].ratio.video,
    md: DIMENSIONS['md'].width['1/2'].ratio.video,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.video,
    container: DIMENSIONS['container'].width['1/2'].ratio.video,
};

const activeIndex = ref(0);
const activeMediaIndex = ref(0);
provide('activeIndex', activeIndex);

const mediaRefs = ref([]);
const mediaWrapper = ref([]);
const mobileTextOverlayHeight = ref(0);

const components = mapNodes(props.main);

watch(activeIndex, () => {
    activeMediaIndex.value = components.findLastIndex((node, index) => {
        const inRange = index <= activeIndex.value;
        const hasMedia = hasVideo(node.video) || containsImage(node.image);
        return inRange && hasMedia;
    });
});

watch(activeMediaIndex, () => {
    mediaRefs.value.forEach(({ playVideo = () => undefined, pauseVideo = () => undefined }, index) => {
        index === activeMediaIndex.value ? playVideo() : pauseVideo();
    });

    mobileTextOverlayHeight.value = `${
        mediaWrapper?.value?.[activeMediaIndex.value]?.getBoundingClientRect()?.height ?? 0
    }px`;
});

const topMediaOffset = ref('10vh');
const bottomMediaOffset = ref('10vh');

watch(viewPortWidth, () => {
    const components = mapNodes(props.main);
    const firstMediumIndex = components.findIndex(node => {
        return hasVideo(node.video) || containsImage(node.image);
    });
    const firstMedium = mediaWrapper.value?.[firstMediumIndex];
    topMediaOffset.value = firstMedium ? `${firstMedium?.getBoundingClientRect()?.height * 0.5}px` : '10vh';

    const lastMediumIndex = components.findLastIndex(node => {
        return hasVideo(node.video) || containsImage(node.image);
    });
    const lastMedium = mediaWrapper.value?.[lastMediumIndex];
    bottomMediaOffset.value = lastMedium ? `${lastMedium?.getBoundingClientRect()?.height * 0.5}px` : '10vh';
});

const component = ref(null);
const componentIsVisible = ref(false);

useIntersectionObserver(component, ([{ isIntersecting }]) => {
    componentIsVisible.value = isIntersecting;
});

watch(componentIsVisible, () => {
    const { y } = component.value.getBoundingClientRect();
    if (y < 0) {
        // component is above fold. set index to last
        activeIndex.value = components.value.length - 1;
    } else {
        // component is below fold. set index to first
        activeIndex.value = 0;
    }
});

const getImagingBreakpoints = ratio => {
    if (ratio === 'tv') {
        return BREAKPOINTS_TV;
    } else if (ratio === 'video') {
        return BREAKPOINTS_VIDEO;
    } else if (ratio === 'original') {
        return BREAKPOINTS_ORIGINAL;
    }
    return BREAKPOINTS_SQUARE;
};
</script>

<style scoped>
.mobile-text-overlay {
    height: v-bind(mobileTextOverlayHeight);
}

.sticky-area {
    padding-bottom: v-bind(mobileTextOverlayHeight);
    @apply transition-transform;
    transform: translateY(var(--sticky-header-height, 0px));
    @screen md {
        @apply pb-0;
        transform: none;
    }
}

.scrollable-text-image-video {
    @apply pt-[50vh];

    @screen md {
        padding-top: v-bind(topMediaOffset);
        padding-bottom: v-bind(bottomMediaOffset);
    }
}
</style>
