<template>
    <div ref="teaserTile" class="relative rounded-2xl overflow-hidden safari-overflow-hidden-fix group">
        <parallax-container class="relative inset-0">
            <div
                class="aspect-3/4 transition-transform group-hover:scale-110 duration-300"
                :class="ratio === 'postcard' ? 'xl:aspect-3/2' : 'xl:aspect-square'"
            >
                <PictureElement
                    :img="image"
                    :breakpoints="BREAKPOINTS"
                    :class="{
                        hidden: containsImage(imageMobile),
                        'xl:inline': containsImage(imageMobile),
                    }"
                    img-class="block object-cover w-full h-full"
                ></PictureElement>
                <PictureElement
                    v-if="containsImage(imageMobile)"
                    :img="imageMobile"
                    :breakpoints="BREAKPOINTS"
                    class="xl:hidden"
                    img-class="block object-cover w-full h-full"
                ></PictureElement>
            </div>
        </parallax-container>
        <div
            class="absolute top-0 inset-x-0 h-1/3 bg-gradient-to-b from-black to-transparent"
            :class="contentToTailwindClasses({ opacity: opacity ?? 75 })"
        ></div>
        <div
            class="absolute bottom-0 inset-x-0 h-1/3 bg-gradient-to-t from-black to-transparent"
            :class="contentToTailwindClasses({ opacity: opacity ?? 75 })"
        ></div>
        <div class="flex flex-col justify-between items-start absolute inset-0 p-6 xl:p-10 text-white">
            <div>
                <HeadlineComponent
                    class="text-2xl xl:text-3xl mb-4 font-medium"
                    :headline="headline"
                    tag="h3"
                ></HeadlineComponent>
                <p>{{ subline }}</p>
            </div>
            <CallToActionComponent stretch-link="true" :link="link" @click="trackPromoClick"></CallToActionComponent>
        </div>
    </div>
</template>

<script setup>
import { contentToRatio, DIMENSIONS } from '~/utils/helper/imaging';
import { containsImage } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import ParallaxContainer from '../../elements/ParallaxContainer';
import HeadlineComponent from './HeadlineComponent';
import PictureElement from '../../elements/PictureElement';
import CallToActionComponent from './CallToActionComponent';

const props = defineProps(['headline', 'subline', 'image', 'imageMobile', 'link', 'opacity', 'metadata']);

const teaserTile = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
    metadata: props?.metadata,
    creativeName: props?.headline?.text,
});

const ratio = inject('ratio');
const BREAKPOINTS = {
    start: DIMENSIONS['start']?.ratio['3/4']?.scale['1.5'],
    sm: DIMENSIONS['sm'].ratio['3/4']?.scale['1.5'],
    md: DIMENSIONS['md'].width['1/2']?.ratio['3/4']?.scale['1.5'],
    xl: DIMENSIONS['xl'].width['1/2']?.ratio[contentToRatio(ratio ?? 'square')]?.scale['1.5'],
};

onMounted(() => {
    trackPromoView(teaserTile);
});
</script>
