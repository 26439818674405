<template>
    <div ref="categoryTile" class="relative w-full rounded-2xl overflow-hidden safari-overflow-hidden-fix group">
        <ParallaxContainer class="relative inset-0">
            <PictureElement
                img-class="aspect-3/4 object-cover transition-transform group-hover:scale-110 duration-300 w-full h-full"
                :img="image"
                :breakpoints="imageBreakpoints"
            ></PictureElement>
        </ParallaxContainer>
        <div class="absolute top-0 inset-x-0 h-1/3 bg-gradient-to-b from-black to-transparent opacity-60"></div>
        <div class="absolute bottom-0 inset-x-0 h-1/3 bg-gradient-to-t from-black to-transparent opacity-60"></div>
        <div class="absolute inset-0 flex flex-col items-start justify-between text-white h-full p-6 overflow-hidden">
            <LinkComponent :link="link" class="stretched-link" @click="trackPromoClick">
                <HeadlineComponent
                    class="text-lg xl:text-2xl pb-2 font-medium"
                    :headline="headline"
                    tag="h3"
                ></HeadlineComponent>
            </LinkComponent>
            <TagList :tags="tags"></TagList>
        </div>
    </div>
</template>
<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import TagList from '~/templates/components/TagList.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';
import ParallaxContainer from '~/templates/elements/ParallaxContainer.vue';
const props = defineProps(['headline', 'image', 'link', 'tags', 'metadata']);

const categoryTile = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
    metadata: props?.metadata,
    creativeName: props?.tags?.[0]?.name,
});
const imageBreakpoints = {
    start: DIMENSIONS['start'].width['10/12'].ratio['3/4'].scale['1.5'],
    sm: DIMENSIONS['sm'].width['10/12'].ratio['3/4'].scale['1.5'],
    md: DIMENSIONS['md'].width['1/2'].ratio['3/4'].scale['1.5'],
    xl: DIMENSIONS['xl'].width['1/4'].ratio['3/4'].scale['1.5'],
    container: DIMENSIONS['container'].width['1/4'].ratio['3/4'].scale['1.5'],
};

onMounted(() => {
    trackPromoView(categoryTile);
});
</script>
