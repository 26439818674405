<template>
    <slot v-if="isPreview" />
    <div v-else class="min-h-screen flex flex-col items-center w-full h-full">
        <div
            v-if="isDesktop"
            class="fixed bg-black bg-opacity-75 z-40 opacity-0 transition-opacity"
            :class="{
                'opacity-100': isMenuOpen,
                invisible: !isMenuOpen,
                'pointer-events-none': !isMenuOpen,
                'inset-0': isMenuOpen,
            }"
        ></div>

        <PageHeader class="print:hidden" @submenu-open="onSubmenuOpen()" @submenu-close="onSubmenuClose()"></PageHeader>
        <main id="main" class="flex-1 w-full overflow-x-clip" role="main">
            <PageNotifications></PageNotifications>
            <slot />
        </main>
        <PageFooter></PageFooter>
    </div>
</template>

<script setup>
import PageHeader from '~/templates/partials/PageHeader';
import PageFooter from '~/templates/partials/PageFooter';
import PageNotifications from '~/templates/partials/PageNotifications.vue';
import { useNotifications } from '@shopware-pwa/composables-next';
import { isComponentPreview } from '~/utils/content/magnolia';

const isPreview = isComponentPreview();

const { isDesktop } = useDevice();
useNotifications();
</script>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';

export default {
    name: 'PageLayout',
    mixins: [editableComponent],
    data: () => ({
        isMenuOpen: false,
    }),
    methods: {
        onSubmenuOpen() {
            this.isMenuOpen = true;
        },
        onSubmenuClose() {
            this.isMenuOpen = false;
        },
    },
};
</script>
