<template>
    <div class="border p-4 rounded-md">
        <div class="grid grid-cols-[min-content_1fr] gap-4">
            <ProductImage
                class="w-20"
                :product-media="contract?.contractPlan?.product?.cover?.media"
                main-class="product-image flex-shrink-0 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey"
                fallback-class="product-image flex-shrink-0 object-contain object-center aspect-[74/100] rounded-lg bg-light-grey p-2"
            ></ProductImage>

            <div class="flex flex-col gap-2">
                <SubscriptionStatus :status="contract?.status" class="self-start" />

                <div class="grid grid-cols-2">
                    <div class="font-medium text-xl">{{ plan?.name }}</div>

                    <div class="hidden xl:block justify-self-end">
                        <TextSelectField
                            ref="subscriptionActionSelectField"
                            v-model="selectedMenuOption"
                            :options="menuOptions"
                            :label="commonLabels?.subscriptionActions"
                        >
                            <CommonLabel
                                path="BWF/shop/account/subscriptions/contract/subscriptionActions"
                                class="text-sm"
                            ></CommonLabel>
                        </TextSelectField>
                    </div>
                </div>

                <hr class="my-4 xl:my-0" />

                <div class="grid gap-4 xl:grid-cols-3 text-sm hyphens-auto">
                    <div>
                        <h4 class="font-medium mb-2">
                            <CommonLabel path="BWF/shop/shared/shippingAddress"></CommonLabel>
                        </h4>

                        <div>
                            <h5>
                                {{ `${contract?.shippingAddress?.firstName} ${contract?.shippingAddress?.lastName}` }}
                            </h5>
                            <div>{{ contract?.shippingAddress?.street }}</div>
                            <div>{{ contract?.shippingAddress?.additionalAddressLine1 }}</div>

                            <div>{{ contract?.shippingAddress?.zipcode }} {{ contract?.shippingAddress?.city }}</div>
                            <div>{{ contract?.shippingAddress?.country?.name }}</div>
                        </div>
                    </div>
                    <div>
                        <h4 class="font-medium mb-2">
                            <CommonLabel path="BWF/shop/account/subscriptions/contract/cylinderLabel"></CommonLabel>
                        </h4>
                        <div class="flex flex-col">
                            <CommonLabel
                                class="text-medium-grey"
                                path="BWF/shop/account/subscriptions/contract/cylinderAtHome"
                            ></CommonLabel>
                            <CommonLabel
                                path="BWF/shop/account/subscriptions/contract/cylindersAtHomeInformation"
                                :placeholder="{
                                    amount: contract?.deposit?.depositItemQuantity,
                                    label: plan?.product?.customFields?.['contract_deposit_line_item_label'],
                                    price: getIntlFormattedPrice(contract?.deposit?.depositItemUnitPrice),
                                }"
                            ></CommonLabel>
                            <CommonLabel
                                class="text-medium-grey"
                                path="BWF/shop/account/subscriptions/contract/contractDeposit"
                            ></CommonLabel>
                            <SharedPrice :value="contract?.deposit?.contractTotalDepositAmount"></SharedPrice>
                            <h4 class="font-medium my-2">
                                <CommonLabel
                                    path="BWF/shop/account/subscriptions/contract/lastChangeLabel"
                                ></CommonLabel>
                            </h4>
                            <FormattedDate v-if="lastChangedDate" :date="lastChangedDate"></FormattedDate>
                        </div>
                    </div>
                    <div>
                        <h4 class="font-medium mb-2">
                            <CommonLabel path="BWF/shop/account/subscriptions/contract/paymentLabel"></CommonLabel>
                        </h4>
                        <div>
                            {{ contract?.paymentMethod?.name }}
                        </div>

                        <CommonLabel
                            v-if="creditCardNumber"
                            path="BWF/shop/account/subscriptions/contract/creditCardNumber"
                            :placeholder="{ creditCardNumber }"
                        />

                        <div v-if="paymentExpired || paymentExpiringSoon" class="mt-2 text-red-500">
                            <CommonLabel
                                v-if="paymentExpired"
                                path="BWF/shop/account/subscriptions/contract/paymentExpired"
                            />
                            <CommonLabel
                                v-if="paymentExpiringSoon"
                                path="BWF/shop/account/subscriptions/contract/paymentExpiringSoon"
                            />
                            <button class="text-link" @click="$emit('contract-change-payment')">
                                <CommonLabel path="BWF/shop/account/subscriptions/contract/updatePaymentMethod" />
                            </button>
                        </div>

                        <span v-else>{{ contract?.customFields?.paymentDetails?.consumerAccount }}</span>
                    </div>
                </div>
            </div>
            <div class="col-span-full mt-4 xl:hidden">
                <hr />
                <TextSelectField
                    ref="orderActionSelectField"
                    v-model="selectedMenuOption"
                    class="p-4 pb-0 justify-center"
                    :options="menuOptions"
                    :label="commonLabels?.subscriptionActions"
                >
                    <CommonLabel
                        path="BWF/shop/account/subscriptions/contract/subscriptionActions"
                        class="text-sm"
                    ></CommonLabel>
                </TextSelectField>
            </div>
        </div>

        <ModalComponent :controller="addressModal">
            <template #headline>
                <p class="block text-center text-lg md:text-2xl">
                    <CommonLabel
                        path="BWF/shop/account/subscriptions/contract/subscriptionActionAddressEdit"
                    ></CommonLabel>
                </p>
            </template>
            <AccountAddressForm
                :address="contract?.shippingAddress"
                @close-modal="addressModal.close()"
                @address-submit="onAddressSave"
            ></AccountAddressForm>
        </ModalComponent>
    </div>
</template>

<script setup lang="ts">
import TextSelectField from '~/templates/elements/form/TextSelectField.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import FormattedDate from '~/templates/elements/FormattedDate.vue';
import AccountAddressForm from '~/templates/components/shop/account/AccountAddressForm.vue';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import { isCancelled } from '~/utils/helper/shop/subscription';
import ProductImage from '~/templates/elements/ProductImage.vue';

const { getLabels } = useCommonLabels();
const { getIntlFormattedPrice } = usePrice();
const { apiInstance } = useShopwareContext();
const { pushSuccess, pushError } = useNotifications();

const props = defineProps(['contract', 'cancellationModalArea', 'cancellationSuccessModalArea']);

const emit = defineEmits<{
    (e: 'contract-update'): void;
    (e: 'contract-cancel'): void;
    (e: 'contract-change-payment'): void;
}>();

const commonLabels = await getLabels('BWF/shop/account/subscriptions/contract', [
    'subscriptionActions',
    'subscriptionActionReturn',
    'subscriptionActionCancel',
    'subscriptionChangePayment',
    'cancellationReasons',
    'subscriptionActionAddressEdit',
    'setAddressSuccess',
]);

const plan = computed(() => props?.contract?.contractPlan);
//
const addressModal = useModal();

const menuOptions = ref([
    {
        label: commonLabels.subscriptionActionCancel,
        value: 'cancel',
        disabled: isCancelled(props?.contract),
    },
    {
        label: commonLabels.subscriptionChangePayment,
        value: 'payment',
        disabled: isCancelled(props?.contract),
    },
    {
        label: commonLabels.subscriptionActionAddressEdit,
        value: 'addressEdit',
        disabled: isCancelled(props?.contract),
    },
]);
const creditCardNumber = computed(() => {
    return props?.contract?.customFields?.paymentDetails?.cardNumber ?? false;
});

const lastChangedDate = computed(() => {
    const webContractOrders = props.contract?.webContractOrders ?? [];

    // @ts-ignore
    webContractOrders.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        // @ts-ignore Sort by latest date in descending order
        return dateB - dateA; // @ts-ignore Sort by latest date in descending order
    });

    return webContractOrders[0]?.order?.orderDate;
});

const subscriptionActionSelectField = ref(null);
const selectedMenuOption = ref('');
watch(selectedMenuOption, option => {
    switch (option) {
        case 'addressEdit':
            addressModal.open();
            break;
        case 'cancel':
            emit('contract-cancel');
            break;
        case 'payment':
            emit('contract-change-payment');
            break;
    }
    // reset options
    //@ts-ignore
    subscriptionActionSelectField.value?.reset();
});

//@ts-ignore
const onAddressSave = async formState => {
    try {
        //@ts-ignore
        const { error } = await apiInstance.invoke.patch(
            `/store-api/contract/${props.contract?.id}/address/${props.contract?.shippingAddress?.id}`,
            formState
        );
        if (error?.value) {
            pushError(error?.value?.messages?.[0]?.detail);
        } else {
            pushSuccess(commonLabels.setAddressSuccess);
        }
        addressModal.close();
        emit('contract-update');
    } catch (e) {
        //@ts-ignore
        pushError(e?.messages?.[0]?.detail);
    }
};

const paymentExpiringSoon = ref(false);
const paymentExpired = ref(false);

const checkExpiration = () => {
    const expiryState = props?.contract?.extensions?.creditCardExpiryState?.value;
    if (expiryState === 'expired' || props?.contract?.status === 'payment_update_requested') {
        paymentExpired.value = true;
    }
    if (expiryState === 'expiringSoon') {
        paymentExpiringSoon.value = true;
    }
};

onMounted(() => {
    checkExpiration();
});
</script>
