<template>
    <img
        v-if="column?.differentMobileContent?.icon || column?.icon"
        :src="
            imagePath(
                isMobile && column?.differentMobileContent?.icon ? column?.differentMobileContent?.icon : column?.icon
            )
        "
        :alt="
            getImageAlt(
                isMobile && column?.differentMobileContent?.icon ? column?.differentMobileContent?.icon : column?.icon
            )
        "
        class="h-5 w-5"
    />
    <LinkComponent
        ref="promoBannerColumn"
        :link="isMobile && column?.differentMobileContent?.link ? column?.differentMobileContent?.link : column?.link"
        class="ml-2"
        @click="trackPromoClick"
    >
        {{
            isMobile && column?.differentMobileContent?.commonLabel
                ? column?.differentMobileContent?.commonLabel?.value
                : column?.commonLabel?.value
        }}</LinkComponent
    >
</template>

<script setup>
import { getImageAlt, imagePath } from '~/utils/helper/magnolia';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
const props = defineProps(['column']);
const { isMobile } = useDevice();

const promoBannerColumn = ref(null);
const { trackPromoView, trackPromoClick } = usePromoTracking({
    promoIndex: props?.column['@name'],
    creativeName: props?.column?.commonLabel['@name'],
    promoName: 'promo_banner',
});

onMounted(() => {
    trackPromoView(promoBannerColumn);
});
</script>
