<template>
    <!--    Grid Header-->
    <div v-if="columnNames" class="hidden cart-grid xl:grid gap-4 text-xs font-medium py-2 xl:mb-6 border-b">
        <div>{{ cartLabels.tableProduct }}</div>
        <div class="col-start-3 text-center">{{ sharedLabels.tableQuantity }}</div>
        <div class="col-start-4 text-end">{{ cartLabels.tableTotal }}</div>
        <div></div>
    </div>

    <!--      Default Card Layout with simple variant_  -->
    <div class="relative grid cart-grid align-start">
        <NuxtLink v-if="!isEshopSet" :to="pdpUrl" class="col-span-4 row-span-3 xl:col-span-1 xl:row-span-6">
            <ProductImage
                v-if="!isEshopSet"
                :product-media="thumbnailUrl"
                :product-name="cartItem?.label"
                main-class="product-image object-contain object-center rounded-lg bg-light-grey aspect-[74/100]"
                fallback-class="w-full h-full object-center rounded-lg bg-light-grey aspect-[74/100] p-4"
            >
            </ProductImage>
        </NuxtLink>
        <div
            class="flex flex-col gap-1 col-span-5 xl:col-span-1 hyphens-auto"
            :class="{ 'col-span-9 xl:col-span-2': isEshopSet, 'col-span-8 xl:col-span-4': simpleVariant }"
        >
            <NuxtLink :to="pdpUrl">
                <h3 class="text-base product-title" data-testid="cart-product-name">
                    {{ cartItem.label }}
                </h3>
            </NuxtLink>
            <div class="leading-tight">
                <p v-if="productNumber" class="inline-flex gap-1 text-sm text-medium-grey">
                    <CommonLabel path="BWF/shop/productDetailPage/productNumber"></CommonLabel>
                    <span>
                        {{
                            //@ts-ignore
                            cartItem.payload?.productNumber ?? ''
                        }}
                    </span>
                </p>

                <dl v-if="itemOptions" class="product-options text-sm text-medium-grey">
                    <template v-for="option in itemOptions" :key="(option as PropertyGroupOptionCart).group">
                        <dd>{{ (option as PropertyGroupOptionCart).option }}</dd>
                    </template>
                </dl>
            </div>
            <span v-if="cartItem?.price?.referencePrice?.unitName" class="text-sm text-medium-grey">
                <span
                    >{{ cartItem?.price?.referencePrice?.purchaseUnit }}
                    {{ cartItem?.price?.referencePrice?.unitName }}&nbsp;</span
                >({{ getIntlFormattedPrice(cartItem?.price?.referencePrice?.price) }}&nbsp;/&nbsp;{{
                    cartItem?.price?.referencePrice?.referenceUnit
                }}
                {{ cartItem?.price?.referencePrice?.unitName }})</span
            >
        </div>
        <div v-if="!simpleVariant" class="flex flex-col items-end ml-auto col-span-3 xl:col-span-1">
            <SharedPrice
                v-if="cartItem.price?.totalPrice >= 0 && !isExchangeBox"
                class="product-total-price"
                :class="{
                    'hidden xl:block': simpleVariant,
                    'font-medium': !simpleVariant,
                    'text-signal-red': !simpleVariant && isListPrice,
                }"
                :value="cartItem.price?.totalPrice"
                has-asterisk
            />
            <SharedPrice
                v-if="!simpleVariant && isListPrice"
                class="font-normal text-base leading-snug line-through"
                :value="totalListPrice"
                has-asterisk
            />
        </div>
        <CheckoutCartItemExchangeBoxChildren
            v-if="isExchangeBox"
            class="col-start-5 row-start-2 col-span-full grid grid-cols-[2fr_1fr] auto-rows-min text-sm xl:contents"
            :cart-item="cartItem"
        ></CheckoutCartItemExchangeBoxChildren>

        <ul v-if="isEshopSet" class="col-span-full grid gap-2">
            <!--          NESTED PRODUCTS-->
            <li v-for="(child, index) in cartItem.children" :key="child.id">
                <CheckoutCartItem
                    v-if="!isChildHidden(index)"
                    :cart-item="child"
                    :simple-variant="true"
                    :parent-pdp-url="pdpUrl"
                />
            </li>
        </ul>

        <div
            v-if="!simpleVariant"
            class="product-amount w-full text-sm col-span-9 xl:col-span-1 xl:col-start-3 xl:row-start-1"
            :class="{ 'invisible h-0': isExchangeBox }"
        >
            <SelectField
                v-model="quantity"
                :options="quantityOptions"
                :readonly="maxQty > 1 ? null : true"
                :disabled="maxQty > 1 ? null : true"
                name="quantity"
            ></SelectField>
        </div>

        <div
            v-if="!simpleVariant"
            class="product-remove col-span-3 justify-self-end self-center xl:col-span-1 xl:self-start xl:col-start-5 xl:row-start-1"
        >
            <button class="text-black" @click="removeCartItem">
                <SvgIcon
                    name="Remove-Item"
                    type="button"
                    class="text-link text-black h-4 w-4"
                    :class="{ 'animate-pulse': isLoading }"
                >
                </SvgIcon>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { LineItem, PropertyGroupOptionCart } from '@shopware-pwa/types';
import SharedPrice from '~/templates/components/shop/shared/SharedPrice.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import CheckoutCartItemExchangeBoxChildren from '~/templates/components/shop/checkout/CheckoutCartItemExchangeBoxChildren.vue';
import { usePrice } from '~/composables/shop/usePrice';
import ProductImage from '~/templates/elements/ProductImage.vue';

const { getIntlFormattedPrice } = usePrice();
const props = defineProps<{
    cartItem: LineItem;
    columnNames?: boolean;
    simpleVariant?: boolean;
    parentPdpUrl?: string;
}>();
const isLoading = ref(false);

const { getLabels } = useCommonLabels();
const cartLabels = await getLabels('BWF/shop/checkout/cart');
const sharedLabels = await getLabels('BWF/shop/shared');

const { cartItem } = toRefs(props);
const { itemOptions, removeItem, itemQuantity, changeItemQuantity } = useCartItem(cartItem);

const quantity = ref(0);
syncRefs(itemQuantity, quantity);

const maxQty = ref(props.cartItem.quantityInformation?.maxPurchase ?? 1);

const quantityOptions = computed(() => {
    const options: never = [];
    for (let i = 1; i <= maxQty.value; i++) {
        options.push({ value: i });
    }
    return options;
});

const updateQuantity = async (quantity: number | undefined) => {
    if (quantity === itemQuantity.value) return;
    isLoading.value = true;
    await changeItemQuantity(Number(quantity));
    isLoading.value = false;
};

const thumbnailUrl = computed(() => {
    if (cartItem.value.cover) {
        return cartItem?.value?.cover;
    } else {
        return cartItem.value?.children?.[0]?.cover;
    }
});

//@ts-ignore
const isEshopSet = computed(() => cartItem?.value?.extensions?.hasOwnProperty('eshopSetData') ?? false);
//@ts-ignore
const isExchangeBox = computed(() => cartItem?.value?.children?.[0]?.extensions?.hasOwnProperty('tauschbox') ?? false);
const isListPrice = computed(() => cartItem?.value?.price?.listPrice);

/*
 * checks wether a child is the first item AND should be hidden
 * OR
 * ist a hidden Item/ SKU
 * */
const isChildHidden = (index: number) => {
    const child = cartItem.value?.children?.[index];
    return !!(
        ((index === 0 && (cartItem?.value?.extensions?.brita_eshop_set_hide_main_product_image?.value ?? false)) ||
            child?.extensions?.eshopSet?.eshopSetProductIsHidden) ??
        false
    );
};
// TODO: only temporary, rebuild if backend provides an extra property for this.
//@ts-ignore
const totalListPrice = computed(
    () => cartItem?.value?.price?.listPrice?.price * (cartItem?.value?.price?.quantity ?? 0)
);
const productNumber = computed(() => {
    //@ts-ignore
    return cartItem?.value.payload?.productNumber;
});

watch(quantity, () => updateQuantity(quantity.value));

const { trackItemRemoveFromCart } = useCheckoutTracking();
const removeCartItem = async () => {
    isLoading.value = true;
    // track before remove, otherwise item is gone in the next tick
    trackItemRemoveFromCart(cartItem);
    await removeItem();
    isLoading.value = false;
};

const { getPdpUrlByProduct, getEShopSetUrlByProduct } = useMagnoliaShopPages();

const pdpUrl = ref('');
if (props.parentPdpUrl) {
    pdpUrl.value = toRaw(props.parentPdpUrl);
} else if (isExchangeBox.value) {
    // set urls
    const payload = cartItem?.value.children[0]?.payload;
    // @ts-ignore
    pdpUrl.value = await getPdpUrlByProduct(payload, payload?.productNumber);
} else if (isEshopSet.value) {
    // set urls
    pdpUrl.value = await getEShopSetUrlByProduct(cartItem?.value);
    // @ts-ignore
} else if (cartItem.value.payload?.parentId || cartItem.value.payload?.referencedId) {
    // variant urls
    pdpUrl.value = await getPdpUrlByProduct(cartItem?.value?.payload, cartItem.value.id);
} else {
    // no variant url
    pdpUrl.value = await getPdpUrlByProduct(cartItem?.value);
}
</script>

<style>
.cart-grid {
    @apply grid-cols-12 xl:grid-cols-5 gap-4 xl:gap-y-0.5;
    @screen xl {
        grid-template-columns: 3fr 5fr 1.2fr 2fr 1fr;
    }
}
</style>
