import { isEditMode, mgnlVersionedRequest, setURLSearchParams } from '~/utils/content/magnolia';

import useMagnoliaLanguage from './useMagnoliaLanguage';
import useMagnoliaSite from '~/composables/useMagnoliaSite';
import { copyMasterToClient } from '~/utils/content/masterComponent';

const useMagnoliaContent = function () {
    const content = useState('mgnl-content', () => ({}));

    const { currentLanguage } = useMagnoliaLanguage();
    const transformMagnoliaContent = function (value, isInEditMode) {
        try {
            const stringified = JSON.stringify(value, function (key, val) {
                if (!isInEditMode && val['@nodeType'] === 'mgnl:area' && val.hasOwnProperty('@nodes')) {
                    // filter nodes to be rendered based on hiddenComponent flag
                    val['@nodes'] = val['@nodes'].filter(
                        nodeName => val[nodeName] && val[nodeName].hiddenComponent !== true
                    );
                }

                return copyMasterToClient(val);
                // return val;
            });

            return JSON.parse(stringified);
        } catch (e) {
            console.log('CONTENT TRANSFORM ERR: ', e.message);
            return value;
        }
    };

    const loadContent = async contentPath => {
        // load content
        // const { content: pathContent, error } = await getContentByPath(contentPath, currentLanguage.value);

        const {
            public: { mgnlApi },
        } = useRuntimeConfig();
        const pagesApi = `${mgnlApi}/.rest/delivery/pages/v1`;

        if (!contentPath || !currentLanguage) {
            // prevent broken author requests
            showError({ statusCode: 404, statusMessage: 'invalid content request. contentPath or language missing' });
            return {};
        }
        const isInEditMode = isEditMode();
        let pageUrl = setURLSearchParams(`${pagesApi}${contentPath}`, `lang=${currentLanguage.value}`);

        let version = mgnlVersionedRequest();
        if (version) {
            pageUrl = setURLSearchParams(pageUrl, `version=${version}`);
        }
        const { mgnlSiteHeaders } = useMagnoliaSite();

        // PathVariable contains the PART OF THE URL THAT COULD NOT BE MAPPED BY MAGNOLIA and is considered to be a var
        const key = `mgnl-content-${contentPath}`;

        const { data: pathContent, error } = await useFetch(pageUrl, {
            key,
            headers: mgnlSiteHeaders,
            timeout: 5000,
            transform: input => transformMagnoliaContent(input, isInEditMode),
        });

        if (error?.value) {
            throw createError({
                statusCode: error.value.statusCode,
                statusMessage: error.value.message,
            });
        }

        content.value = pathContent.value;
    };

    const friendlyUrlMissing = computed(() => (content.value?.friendlyUrl ?? '').length === 0);
    // const canonical = computed(() => content.value?.cCanonicalLink);
    const mgnlContentPath = computed(() => content.value?.['@path'] ?? '');

    return {
        loadContent,
        content,
        pageHeader: computed(() => content.value?.cNavigation),
        pageFooter: computed(() => content.value?.cFooter),
        pageBanner: computed(() => content.value?.cBanner),
        pathVariable: computed(() => content.value?.cPathVariable),
        canonical: computed(() => content.value?.cCanonicalLink),
        robots: computed(() => content.value?.cRobots),
        pageTemplate: computed(() => content.value?.['mgnl:template'] ?? 'no-mgnl-tpl'),
        friendlyUrlMissing,
        mgnlContentPath,
    };
};

export default useMagnoliaContent;
