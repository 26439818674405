<template>
    <div class="my-4 grid gap-4 xl:grid-cols-3 xl:gap-8">
        <div class="xl:col-span-2 flex flex-col gap-4 xl:gap-2">
            <div class="grid gap-6 mb-8">
                <div>
                    <HeadlineComponent tag="h1" class="text-lg xl:text-4xl xl:mb-6 font-medium text-dark-blue">
                        <CommonLabel path="BWF/shop/checkout/addressForm/headline"></CommonLabel>
                    </HeadlineComponent>
                    <div v-if="!isAuthenticated" class="flex flex-col xl:flex-row gap-4 items-center">
                        <hr class="xl:hidden mt-4 w-full" />
                        <CommonLabel
                            class="text-center xl:text-left"
                            path="BWF/shop/checkout/addressForm/alreadyRegisteredCTA"
                        ></CommonLabel>
                        <button class="hidden xl:flex text-link" @click="loginMsal">
                            <CommonLabel path="BWF/shop/shared/loginNow"></CommonLabel>
                        </button>
                        <button class="btn btn-blue xl:hidden w-full" @click="loginMsal">
                            <CommonLabel path="BWF/shop/checkout/addressForm/loginAccount"></CommonLabel>
                        </button>
                    </div>
                </div>

                <hr />

                <form
                    id="checkout-billing-address"
                    ref="addressForm"
                    class="grid gap-6"
                    name="checkout-billing-address"
                    method="post"
                    @submit.prevent="invokeSubmit"
                >
                    <div v-if="registerErrors?.length" class="notification is-red" role="alert">
                        <ul>
                            <li v-for="error in registerErrors" :key="error.detail">
                                {{ error.detail }}
                            </li>
                        </ul>
                    </div>

                    <div class="grid grid-cols-6 gap-4 xl:gap-6">
                        <fieldset class="contents">
                            <legend class="col-span-6 block text-lg font-medium">
                                <CommonLabel path="BWF/shop/checkout/addressForm/personals"></CommonLabel>
                            </legend>

                            <div class="col-span-full xl:col-span-3">
                                <SelectField
                                    v-model="checkoutFormState.salutationId"
                                    :label="checkoutFormLabels?.salutationLabel"
                                    class="form-field-grey"
                                    name="salutationId"
                                    :options="salutationOptions(getSalutations)"
                                    :error-message="checkoutForm$?.salutationId?.$errors?.[0]?.$message"
                                ></SelectField>
                            </div>
                            <div class="hidden xl:block xl:col-span-3"></div>

                            <div class="col-span-6 xl:col-span-3">
                                <InputField
                                    v-model="checkoutFormState.firstName"
                                    class="form-field-grey"
                                    name="first-name"
                                    required="required"
                                    :label="checkoutFormLabels?.firstNameLabel"
                                    :placeholder="checkoutFormLabels?.firstNamePlaceholder"
                                    :error-message="checkoutForm$?.firstName?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.firstName.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-6 xl:col-span-3">
                                <InputField
                                    v-model="checkoutFormState.lastName"
                                    class="form-field-grey"
                                    name="last-name"
                                    required="required"
                                    :label="checkoutFormLabels?.lastNameLabel"
                                    :placeholder="checkoutFormLabels?.lastNamePlaceholder"
                                    :error-message="checkoutForm$?.lastName?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.lastName.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-6 xl:col-span-3">
                                <InputField
                                    v-model="checkoutFormState.email"
                                    class="form-field-grey"
                                    name="email-address"
                                    type="email"
                                    :readonly="!!user"
                                    required="required"
                                    autocomplete="off"
                                    :label="checkoutFormLabels?.eMailAddressLabel"
                                    :placeholder="checkoutFormLabels?.eMailAddressPlaceholder"
                                    :error-message="checkoutForm$?.email?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.email.$touch()"
                                ></InputField>
                            </div>
                        </fieldset>
                    </div>
                    <hr />
                    <div class="grid grid-cols-12 gap-4 xl:gap-6">
                        <fieldset class="contents">
                            <legend class="col-span-full block text-lg font-medium">
                                <CommonLabel path="BWF/shop/shared/address"></CommonLabel>
                            </legend>

                            <div class="col-span-full xl:col-span-6">
                                <InputField
                                    v-model="checkoutFormState.billingAddress.street"
                                    class="form-field-grey"
                                    name="street-address"
                                    :label="checkoutFormLabels?.streetAndHouseNoLabel"
                                    :placeholder="checkoutFormLabels?.streetAndHouseNoPlaceholder"
                                    autocomplete="street-address"
                                    required="required"
                                    :error-message="checkoutForm$?.billingAddress?.street?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.billingAddress.street.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full xl:col-span-3">
                                <InputField
                                    v-model="checkoutFormState.billingAddress.zipcode"
                                    class="form-field-grey"
                                    name="postal-code"
                                    :label="checkoutFormLabels?.postalCodeLabel"
                                    :placeholder="checkoutFormLabels?.postalCodePlaceholder"
                                    autocomplete="postal-code"
                                    required="required"
                                    :error-message="checkoutForm$?.billingAddress?.zipcode?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.billingAddress.zipcode.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full xl:col-span-3">
                                <InputField
                                    v-model="checkoutFormState.billingAddress.city"
                                    class="form-field-grey"
                                    name="city"
                                    required="required"
                                    :label="checkoutFormLabels?.cityLabel"
                                    :placeholder="checkoutFormLabels?.cityPlaceholder"
                                    :error-message="checkoutForm$?.billingAddress?.city?.$errors?.[0]?.$message"
                                    @blur="checkoutForm$.billingAddress.city.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full">
                                <InputField
                                    v-model="checkoutFormState.billingAddress.additionalAddressLine1"
                                    class="form-field-grey"
                                    name="additionalAddressLine1"
                                    :label="checkoutFormLabels?.addressSuffixLabel"
                                    :placeholder="checkoutFormLabels?.addressSuffixPlaceholder"
                                    :error-message="
                                        checkoutForm$?.billingAddress?.additionalAddressLine1?.$errors?.[0]?.$message
                                    "
                                    @blur="checkoutForm$.billingAddress.additionalAddressLine1.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-full xl:col-span-6">
                                <SelectField
                                    v-model="checkoutFormState.billingAddress.countryId"
                                    class="form-field-grey"
                                    name="country"
                                    :label="checkoutFormLabels?.countryLabel"
                                    required="required"
                                    :options="countryOptions(getCountries, 'id', 'name')"
                                    :error-message="checkoutForm$?.billingAddress?.countryId?.$errors?.[0]?.$message"
                                    autocomplete="country-name"
                                    @blur="checkoutForm$.billingAddress.countryId.$touch()"
                                ></SelectField>
                            </div>
                        </fieldset>
                    </div>

                    <div
                        class="col-span-full"
                        @click="forceShippingAddress && pushWarning(notifications.forceDeliveryAddress)"
                    >
                        <CheckboxField
                            v-model="customShipping"
                            :disabled="forceShippingAddress ? 'disabled' : null"
                            :label="checkoutFormLabels.differentShippingAddressLabel"
                        ></CheckboxField>
                    </div>

                    <div v-if="customShipping" class="grid grid-cols-12 gap-4 xl:gap-6">
                        <fieldset class="contents">
                            <legend class="col-span-full block text-lg font-medium">
                                <CommonLabel path="BWF/shop/checkout/addressForm/altAddress"> </CommonLabel>
                            </legend>

                            <div class="col-span-full xl:col-span-6">
                                <SelectField
                                    v-model="shippingFormState.salutationId"
                                    :label="checkoutFormLabels?.salutationLabel"
                                    class="form-field-grey"
                                    name="salutationId"
                                    :options="salutationOptions(getSalutations)"
                                ></SelectField>
                            </div>
                            <div class="hidden xl:flex col-span-6"></div>
                            <div class="col-span-full xl:col-span-6">
                                <InputField
                                    v-model="shippingFormState.firstName"
                                    class="form-field-grey"
                                    name="first-name"
                                    required="required"
                                    :label="checkoutFormLabels?.firstNameLabel"
                                    :placeholder="checkoutFormLabels?.firstNamePlaceholder"
                                    :error-message="shippingForm$.firstName?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.firstName.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-full xl:col-span-6">
                                <InputField
                                    v-model="shippingFormState.lastName"
                                    class="form-field-grey"
                                    name="last-name"
                                    required="required"
                                    :label="checkoutFormLabels?.lastNameLabel"
                                    :placeholder="checkoutFormLabels?.lastNamePlaceholder"
                                    :error-message="shippingForm$.lastName?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.lastName.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-full xl:col-span-6">
                                <InputField
                                    v-model="shippingFormState.street"
                                    class="form-field-grey"
                                    name="street-address"
                                    :label="checkoutFormLabels?.streetAndHouseNoLabel"
                                    :placeholder="checkoutFormLabels?.streetAndHouseNoPlaceholder"
                                    autocomplete="street-address"
                                    required="required"
                                    :error-message="shippingForm$?.street?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.street.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full xl:col-span-3">
                                <InputField
                                    v-model="shippingFormState.zipcode"
                                    class="form-field-grey"
                                    name="postal-code"
                                    :label="checkoutFormLabels?.postalCodeLabel"
                                    :placeholder="checkoutFormLabels?.postalCodePlaceholder"
                                    autocomplete="postal-code"
                                    required="required"
                                    :error-message="shippingForm$?.zipcode?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.zipcode.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full xl:col-span-3">
                                <InputField
                                    v-model="shippingFormState.city"
                                    class="form-field-grey"
                                    name="city"
                                    :label="checkoutFormLabels?.cityLabel"
                                    required="required"
                                    :placeholder="checkoutFormLabels?.cityPlaceholder"
                                    :error-message="shippingForm$?.city?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.city.$touch()"
                                ></InputField>
                            </div>
                            <div class="col-span-full xl:col-span-full">
                                <InputField
                                    v-model="shippingFormState.additionalAddressLine1"
                                    class="form-field-grey"
                                    name="additionalAddressLine1"
                                    :label="checkoutFormLabels?.addressSuffixLabel"
                                    :placeholder="checkoutFormLabels?.addressSuffixPlaceholder"
                                    :error-message="shippingForm$?.additionalAddressLine1?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.additionalAddressLine1.$touch()"
                                ></InputField>
                            </div>

                            <div class="col-span-full xl:col-span-6">
                                <SelectField
                                    v-model="shippingFormState.countryId"
                                    class="form-field-grey"
                                    name="country"
                                    :label="checkoutFormLabels?.countryLabel"
                                    :options="countryOptions(getDeliveryCountries, 'id', 'name')"
                                    autocomplete="country-name"
                                    :error-message="shippingForm$?.countryId?.$errors?.[0]?.$message"
                                    @blur="shippingForm$.countryId.$touch()"
                                ></SelectField>
                            </div>
                        </fieldset>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="isDesktop" class="mt-5 md:mt-0 md:col-span-1 flex flex-col gap-8 align-items-start">
            <CheckoutSummary />
            <div class="grid gap-4">
                <div class="flex items-end justify-between">
                    <h3 class="text-2xl font-medium">
                        <CommonLabel path="BWF/shop/shared/cartHeadline"></CommonLabel>
                    </h3>

                    <div v-if="cartItems.length > 0" class="text-sm">
                        <CommonLabel class="font-medium" path="BWF/shop/shared/tableQuantity"></CommonLabel>&nbsp;{{
                            count
                        }}
                    </div>
                </div>
                <ul role="list" class="-my-4 divide-y divide-gray-200 pl-0">
                    <li
                        v-for="cartItem in getLineItemsWithoutPromotion(cartItems)"
                        :key="cartItem.id"
                        class="flex py-6"
                    >
                        <CheckoutCartItem :cart-item="cartItem" :simple-variant="true" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <hr class="col-span-full my-10 hidden xl:block" />

    <div class="grid gap-8 xl:grid-cols-3">
        <button class="btn btn-blue btn-size-normal mt-4 xl:mt-0" form="checkout-billing-address" type="submit">
            <span>
                <CommonLabel path="BWF/shop/checkout/addressForm/continueToCheckout"></CommonLabel>
            </span>
        </button>
        <div class="xl:col-span-2 xl:order-first">
            <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
                <SvgIcon name="Arrow-Up" class="h-3 w-3 -rotate-90"></SvgIcon>
                <span><CommonLabel path="BWF/shop/shared/backToShop"></CommonLabel> </span>
            </button>
        </div>
        <div class="mt-4 xl:mt-14">
            <CommonLabel class="text-sm" path="BWF/shared/vatFootnote" /><br />
            <CommonLabel class="text-sm mt-2" path="BWF/shop/shared/mandatoryFieldsLabelGuestCheckout" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ClientApiError, ShopwareError } from '@shopware-pwa/types';
import { onMounted, watch } from 'vue';

const { $msal } = useNuxtApp();
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';

import { getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useCartInformation } from '~/composables/shop/useCartInformation';
import { isDummyUser } from '~/utils/helper/shop/user';
import { salutationOptions } from '~/utils/helper/shop/salutations';
import { countryOptions } from '~/utils/helper/shop/countries';

const { count } = useCartInformation();

const { checkoutFormState, shippingFormState, checkoutForm$, shippingForm$ } = await useCheckoutFormState();

provide('input-required-char', '**');

const { isDesktop } = useDevice();

const loginMsal = () => {
    $msal.signIn();
};
const isAuthenticated = computed(() => $msal.isAuthenticated());

const { getCountries } = useCountries();
const { getSalutations } = useSalutations();

const { getLabels } = useCommonLabels();

const checkoutFormLabels = await getLabels('/BWF/shop/shared/addressForm/', [
    'salutationLabel',
    'chooseSalutationLabel',
    'firstNameLabel',
    'firstNamePlaceholder',
    'lastNameLabel',
    'lastNamePlaceholder',
    'streetAndHouseNoLabel',
    'streetAndHouseNoPlaceholder',
    'eMailAddressLabel',
    'eMailAddressPlaceholder',
    'postalCodeLabel',
    'postalCodePlaceholder',
    'cityLabel',
    'cityPlaceholder',
    'addressSuffixLabel',
    'addressSuffixPlaceholder',
    'countryLabel',
]);

const { register, user, userDefaultBillingAddress } = useUser();
const { countryId, refreshSessionContext } = useSessionContext();
// preselect country select with default id
checkoutFormState.billingAddress.countryId = countryId.value ?? '';
shippingFormState.countryId = countryId.value ?? '';

const { cartItems } = useCart();

const { pushWarning, pushError } = useNotifications();

const customShipping = ref(false);

const getDeliveryCountries = computed(() => getCountries.value.filter(country => country.shippingAvailable));

const notifications = await getLabels('BWF/shop/checkout/addressForm/notifications', ['forceDeliveryAddress']);
const forceShippingAddress = computed(() => {
    const selectedCountry = getCountries.value.find(
        country => country.id === checkoutFormState.billingAddress.countryId
    );
    return selectedCountry?.shippingAvailable === false;
});
watch(forceShippingAddress, () => {
    if (forceShippingAddress.value) {
        customShipping.value = true;
        pushWarning(notifications?.forceDeliveryAddress ?? '');
    }
});

watch(cartItems, () => {
    isStillLoading.value = false;
});

const isStillLoading = ref(true);

const emit = defineEmits<{
    (e: 'loadingStart'): void;
    (e: 'loadingEnd'): void;
}>();

onMounted(async () => {
    // id user is authenticated
    emit('loadingStart');

    try {
        if (user.value?.email) {
            // Prefill dumy user data
            checkoutFormState.firstName = user.value?.firstName ?? '';
            checkoutFormState.lastName = user.value?.lastName ?? '';
            checkoutFormState.email = user.value?.email ?? '';
            if (!isDummyUser(user.value)) {
                checkoutFormState.billingAddress.street = userDefaultBillingAddress.value?.street ?? '';
                checkoutFormState.billingAddress.zipcode = userDefaultBillingAddress.value?.zipcode ?? '';
                checkoutFormState.billingAddress.city = userDefaultBillingAddress.value?.city ?? '';
                checkoutFormState.billingAddress.countryId = userDefaultBillingAddress.value?.countryId ?? '';
                checkoutFormState.billingAddress.additionalAddressLine1 =
                    userDefaultBillingAddress.value?.additionalAddressLine1 ?? '';
            }
            // @ts-ignore
            if (user.value?.salutation?.salutationKey !== 'not_specified') {
                checkoutFormState.salutationId = user.value?.salutationId ?? '';
            }
        }
    } catch (e: unknown) {
        console.log(e?.message);
    }

    emit('loadingEnd');
});

const {
    createCustomerAddress,
    updateCustomerAddress,
    setDefaultCustomerShippingAddress,
    setDefaultCustomerBillingAddress,
} = useAddress();
const addressForm = ref(null);
const registerErrors = ref<ShopwareError[]>([]);

const invokeSubmit = async () => {
    checkoutForm$.value.$touch();

    registerErrors.value = [];
    const valid = await checkoutForm$.value.$validate();
    const shippingValid = customShipping.value === false || (await shippingForm$.value.$validate());

    if (valid && shippingValid) {
        try {
            if (user.value && isAuthenticated.value) {
                // incomplete user exists create / edit addresses and set as default
                const { firstName, lastName, salutationId } = checkoutFormState;

                const formData = {
                    firstName,
                    lastName,
                    salutationId,
                };
                const performUpdate = !!userDefaultBillingAddress?.value?.id;
                const saveAddress = performUpdate ? updateCustomerAddress : createCustomerAddress;
                const billingResponse = await saveAddress({
                    ...(performUpdate ? { id: userDefaultBillingAddress?.value?.id } : {}),
                    ...formData,
                    ...checkoutFormState.billingAddress,
                });

                await setDefaultCustomerBillingAddress(billingResponse.id);

                //
                if (customShipping.value) {
                    const shippingResponse = await createCustomerAddress(shippingFormState);
                    await setDefaultCustomerShippingAddress(shippingResponse.id);
                }
            } else {
                // guest checkout
                const formData = {
                    ...checkoutFormState,
                    ...(customShipping.value ? { shippingAddress: shippingFormState } : {}),
                };
                // @ts-ignore
                await register(formData);
            }

            await refreshSessionContext();
        } catch (error) {
            const e = error as ClientApiError;
            registerErrors.value = e.messages;
            pushError(error?.messages?.[0]?.detail);
        }
    }
};
</script>
