<template>
    <nav
        ref="nav"
        class="main-navigation w-full px-8 overflow-hidden"
        :class="{
            hidden: !isMounted,
            'xl:block': !isMounted,
        }"
        role="navigation"
    >
        <ul
            ref="list"
            v-scroll-lock="isMobileMode && isMobileOpen"
            role="list"
            class="navigation-list container flex items-center content-center gap-4 text-black text-sm transition-all"
            :class="{
                'is-mobile': isMobileMode,
                'is-desktop': !isMobileMode,
                'is-mobile-open': isMobileOpen,
            }"
        >
            <li v-for="(entry, index) in entries" :key="entry['@id']" ref="listItems" class="group">
                <LinkComponent
                    v-if="entry['@nodeType'] === 'nav:link'"
                    class="level-one-link font-medium cursor-pointer transition-colors hover:text-deep-blue z-10 whitespace-nowrap"
                    :link="entry.link"
                >
                    {{ entry.link?.label }}
                </LinkComponent>
                <AccordionComponent
                    v-else
                    :id="`nav-${index}`"
                    ref="accordions"
                    :is-active="isMobileMode && isMobileOpen"
                    @on-open="onAccordionOpen"
                    @on-toggle="onAccordionToggle"
                >
                    <template #header>
                        <button
                            class="level-one-link font-medium cursor-pointer transition-colors hover:text-deep-blue z-10 whitespace-nowrap"
                            :class="{ 'text-deep-blue': index === activeMenuIndex }"
                            :data-index="index"
                            @click="toggleSubMenu"
                        >
                            {{ entry.title }}
                        </button>
                    </template>
                    <template #content>
                        <LevelTwo
                            v-if="entry['@nodeType'] === 'nav:main'"
                            :ref="`submenu-${index}`"
                            class="absolute left-0 right-0 top-full"
                            :class="{ invisible: !isMobileMode && index !== activeMenuIndex }"
                            :is-active="index === activeMenuIndex"
                            :data-index="index"
                            :level="entry"
                            @level-close="close"
                        ></LevelTwo>
                    </template>
                </AccordionComponent>
            </li>
            <li>
                <ShopButton
                    v-if="containsLink(header?.shopLink)"
                    :link="header?.shopLink"
                    class="flex md:hidden flex-shrink-0"
                ></ShopButton>
            </li>
            <li v-if="isMobileMode && isMobileOpen && !isAccordionOpen && header.teaserHeadline" class="mt-8 w-full">
                <MenuTeaser
                    :headline="header.teaserHeadline"
                    :subline="header.teaserSubline"
                    :link="header.teaserLink"
                    :img="header.image"
                ></MenuTeaser>
            </li>
        </ul>
    </nav>
</template>

<script>
import LevelTwo from './LevelTwo';
import LinkComponent from '../../components/cms/LinkComponent';
import MenuTeaser from './MenuTeaser';
import AccordionComponent from '../../elements/AccordionComponent';
import { mapNodes, filterNodesByType, containsLink } from '~/utils/helper/magnolia';
import { ref } from 'vue';
import ShopButton from '~/templates/partials/header/ShopButton.vue';

export default {
    name: 'MainNavigation',
    components: { ShopButton, LevelTwo, LinkComponent, MenuTeaser, AccordionComponent },
    provide() {
        return {
            menu_active: computed(() => this.activeMenuIndex !== null),
            isMobileMode: computed(() => this.isMobileMode),
        };
    },
    props: {
        header: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['submenu-toggle', 'mobile-change'],
    setup() {
        const { isDesktop, isTabletOrMobile, viewPortWidth } = useDevice();
        const isMobileMode = ref(isTabletOrMobile.value);

        return { isTabletOrMobile, isMobileMode, isDesktop, viewPortWidth };
    },
    data() {
        return {
            isMobileOpen: false,
            isAccordionOpen: false,
            activeMenu: null,
            activeMenuIndex: null,
            isMounted: false,
        };
    },
    computed: {
        entries() {
            return filterNodesByType(mapNodes(this.header), 'nav:');
        },
    },
    watch: {
        isMobileOpen() {
            this.evaluateMobile();
        },
    },
    mounted() {
        this.isMounted = true;
        this.evaluateMobile();
    },
    methods: {
        containsLink,
        toggleSubMenu(event) {
            const { index } = event.currentTarget.dataset;
            const [submenu] = this.$refs?.[`submenu-${index}`] ?? [];
            const oldActiveIndex = this.activeMenu?.$el.dataset.index ?? -1;
            const closeActive = index === oldActiveIndex;
            this.activeMenu = closeActive ? null : submenu;
            this.activeMenuIndex = this.activeMenu ? parseInt(index) : null;
            this.$emit('submenu-toggle');
        },
        close() {
            if (this.activeMenu) {
                this.activeMenu = null;
                this.activeMenuIndex = null;
                this.$emit('submenu-toggle');
            }
        },
        evaluateMobile() {
            if (!this.$refs.list) {
                return false;
            }
            const { gap, paddingLeft, paddingRight } = getComputedStyle(this.$refs.list);

            let itemWidth = 0;
            if (this.$refs.listItems?.length > 0) {
                const gapWidth = parseInt(gap);
                this.$refs.listItems.forEach(item => {
                    itemWidth += item.scrollWidth;
                });
                itemWidth += (this.$refs.listItems.length - 1) * gapWidth;
                itemWidth += parseInt(paddingLeft) + parseInt(paddingRight);
            }

            this.isMobileMode = itemWidth > this.$refs.list.offsetWidth || this.isTabletOrMobile;

            if (this.isMobileMode) {
                this.close();
            } else {
                this.isMobileOpen = false;
            }

            return !!this.isMobileMode;
        },
        onAccordionOpen(event) {
            this.$refs.accordions.forEach(accordion => {
                if (accordion.id !== event.id) {
                    // close others that have not just been opened
                    accordion.isOpen = false;
                }
            });
            this.$refs.list.scrollTo({ behavior: 'smooth', top: 0 });
        },
        onAccordionToggle() {
            // set state
            this.isAccordionOpen = this.$refs.accordions?.some(acc => acc.isOpen) ?? false;
        },
        setMobileOpen(value) {
            this.isMobileOpen = value;
        },
    },
};
</script>

<style scoped>
.is-mobile {
    @apply opacity-0 h-4;

    .level-two {
        @apply transform-none p-0 top-0 transition-none;
    }

    .navigation-list {
        @apply transition-none;
    }

    .group {
        @apply w-full;
    }

    .level-one-link {
        @apply block truncate;
    }
}

.is-desktop {
    @apply opacity-100;
}

.is-mobile-open {
    --top-offset: theme('spacing.16');
    --bottom-offset: theme('spacing.4');
    @screen md {
        --top-offset: theme('spacing.20');
        --bottom-offset: theme('spacing.10');
    }
    @apply absolute inset-0 opacity-100 h-screen flex flex-col items-start overflow-auto pb-32 scroll-p-24;
    top: var(--top-offset);
    height: calc(100vh - var(--top-offset) - var(--bottom-offset));

    .level-one-link {
        @apply text-lg;
    }

    .level-two {
        @apply relative opacity-100;
    }
}
</style>
