<template>
    <div v-if="getActiveOptionGroups?.length > 0" class="product-variant-configurator">
        <div v-if="isLoading" class="absolute inset-0 flex items-center justify-end z-10 bg-white/75"></div>
        <div v-for="(optionGroup, index) in getActiveOptionGroups" :key="optionGroup.id" class="flex flex-col">
            <fieldset class="block flex-1">
                <legend class="sr-only">Choose a {{ optionGroup.name }}</legend>
                <div v-if="showOptionName" class="flex gap-2">
                    <p class="font-medium text-base leading-snug text-black">{{ optionGroup.name }}:</p>
                    <span v-if="optionGroup.displayType === 'color'">{{ selectedOptionName(index) }}</span>
                </div>
                <div
                    class="switch-container"
                    :class="{
                        'mt-4': showOptionName,
                    }"
                >
                    <template v-for="option in optionGroup.options" :key="option.id">
                        <button
                            v-if="optionGroup.displayType === 'color'"
                            data-testid="product-variant"
                            :aria-label="`color-${option.name ?? 'button'}`"
                            class="switch-btn flex-0 rounded-full flex items-center justify-center overflow-hidden"
                            :class="[isOptionSelected(option.id) ? 'border-dark-blue' : 'border-beige/50']"
                            @click="handleChange(optionGroup.name, option.id, onHandleChange)"
                        >
                            <div
                                class="relative switch-btn-inner rounded-full bg-light-grey border-beige/50"
                                :style="{ backgroundColor: option?.colorHexCode ?? '#000' }"
                            >
                                <span :id="`${option.id}-choice-label`" data-testid="product-variant-text">
                                    <!--                                    {{ option.name }}-->
                                </span>
                                <StrikeThroughLine
                                    v-if="isOptionNotAvailable(option.id) || isOptionNotCombinable(option)"
                                    class="absolute inset-0 h-full w-full text-beige"
                                ></StrikeThroughLine>
                            </div>
                        </button>

                        <button
                            v-else-if="!colorsOnly"
                            data-testid="product-variant"
                            :aria-label="`option-${option.name}`"
                            class="relative btn btn-ghost flex-1 min-w-content overflow-hidden"
                            :class="[
                                {
                                    'border-dark-blue outline outline-dark-blue outline-1': isOptionSelected(option.id),
                                    'border-beige/50': !isOptionSelected(option.id),
                                    'btn-size-xxs md:btn-size-xs': !optionButtonDefault,
                                    'opacity-50 text-medium-grey':
                                        isOptionNotAvailable(option.id) || isOptionNotCombinable(option),
                                },
                            ]"
                            @click="handleChange(optionGroup.name, option.id, onHandleChange)"
                        >
                            <span :id="`${option.id}-choice-label`" data-testid="product-variant-text">
                                {{ option.name }}
                            </span>
                        </button>
                    </template>
                </div>
            </fieldset>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type ComputedRef } from 'vue';
import StrikeThroughLine from '~/templates/partials/header/StrikeThroughLine.vue';
import type { PropertyGroup, PropertyGroupOption } from '@shopware-pwa/types';

const props = defineProps({
    showOptionName: { type: Boolean, default: false },
    optionButtonDefault: { type: Boolean, default: false },
    colorsOnly: { type: Boolean, default: false },
});

const emit = defineEmits<{
    (e: 'change', selected: never): void;
    (e: 'beforeChange', selected: never): void;
}>();
const isLoading = ref<boolean>();
const { handleChange, getOptionGroups, getSelectedOptions, findVariantForSelectedOptions } = useProductConfigurator();

const selectedOptions: ComputedRef<string[]> = computed(() => Object.values(unref(getSelectedOptions)));
const isOptionSelected = (optionId: string) => Object.values(getSelectedOptions.value).includes(optionId);

const getActiveOptionGroups = computed<PropertyGroup[]>(() =>
    props.colorsOnly
        ? getOptionGroups.value.filter((group: PropertyGroup) => group.displayType === 'color')
        : getOptionGroups.value
);

const { product } = useProduct();
const isOptionNotCombinable = (option: PropertyGroupOption): boolean => {
    // @ts-ignore
    return option?.extensions?.combinable?.combinable === false;
};

const isOptionNotAvailable = (optionId: string) => {
    return !product.value.available && isOptionSelected(optionId);
};
const onHandleChange = async () => {
    emit('beforeChange', selectedOptions);
    isLoading.value = true;
    const variantFound = await findVariantForSelectedOptions(unref(selectedOptions));
    emit('change', variantFound);
    isLoading.value = false;
};

const selectedOptionName = (index: number) => {
    const selectedOption = getOptionGroups.value[index].options?.find(
        // @ts-ignores
        option => selectedOptions.value?.includes?.(option.id) ?? false
    );
    return selectedOption?.translated?.name ?? 'Nichts ausgewählt';
};
</script>

<style>
.product-variant-configurator {
    @apply relative flex flex-col gap-2;
}

.switch-container {
    @apply flex flex-wrap gap-2 md:gap-3;
}
.switch-btn {
    @apply h-5 xl:h-7 w-5 xl:w-7 border;
    .switch-btn-inner {
        @apply h-4 xl:h-6 w-4 xl:w-6 border;
    }
}
</style>
