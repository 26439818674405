<template>
    <form @submit.prevent="invokeSave()">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
            <SelectField
                v-model="addressFormState.salutationId"
                :label="addressFormLabels?.salutationLabel"
                class="form-field-grey"
                name="salutationId"
                :options="salutationOptions(getSalutations)"
            />

            <InputField
                v-model="addressFormState.firstName"
                class="form-field-grey col-start-1"
                name="first-name"
                required="required"
                :label="addressFormLabels?.firstNameLabel"
                :placeholder="addressFormLabels?.firstNamePlaceholder"
                :error-message="addressFormVuelidate?.firstName?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.firstName.$touch()"
            />

            <InputField
                v-model="addressFormState.lastName"
                class="form-field-grey"
                name="last-name"
                required="required"
                :label="addressFormLabels?.lastNameLabel"
                :placeholder="addressFormLabels?.lastNamePlaceholder"
                :error-message="addressFormVuelidate?.lastName?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.lastName.$touch()"
            />

            <InputField
                v-model="addressFormState.street"
                class="form-field-grey"
                name="street-address"
                required="required"
                :label="addressFormLabels?.streetAndHouseNoLabel"
                :placeholder="addressFormLabels?.streetAndHouseNoPlaceholder"
                autocomplete="street-address"
                :error-message="addressFormVuelidate?.street?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.street.$touch()"
            />

            <InputField
                v-model="addressFormState.additionalAddressLine1"
                class="form-field-grey"
                name="additionalAddressLine1"
                :label="addressFormLabels?.addressSuffixLabel"
                :placeholder="addressFormLabels?.addressSuffixPlaceholder"
                :error-message="addressFormVuelidate?.additionalAddressLine1?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.additionalAddressLine1.$touch()"
            />

            <InputField
                v-model="addressFormState.zipcode"
                class="form-field-grey"
                name="postal-code"
                required="required"
                :label="addressFormLabels?.postalCodeLabel"
                :placeholder="addressFormLabels?.postalCodePlaceholder"
                autocomplete="postal-code"
                :error-message="addressFormVuelidate?.zipcode?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.zipcode.$touch()"
            />
            <InputField
                v-model="addressFormState.city"
                class="form-field-grey"
                name="city"
                required="required"
                :label="addressFormLabels?.cityLabel"
                :placeholder="addressFormLabels?.cityPlaceholder"
                :error-message="addressFormVuelidate?.city?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.city.$touch()"
            />

            <SelectField
                v-model="addressFormState.countryId"
                class="form-field-grey"
                name="country"
                required="required"
                :label="addressFormLabels?.countryLabel"
                :options="selectableCountries"
                autocomplete="country-name"
                :error-message="addressFormVuelidate?.countryId?.$errors?.[0]?.$message"
                @blur="addressFormVuelidate.countryId.$touch()"
            ></SelectField>
        </div>
        <div class="mt-4 xl:mt-8 text-gray-500 text-sm">
            <CommonLabel path="BWF/shop/shared/mandatoryFieldsLabel"></CommonLabel>
        </div>
        <div class="text-right flex flex-col gap-4 mt-6 xl:gap-6 xl:mt-8 xl:flex-row">
            <button type="submit" class="btn btn-blue">
                <CommonLabel path="BWF/shop/shared/saveChangesLabel"></CommonLabel>
            </button>
            <button type="button" class="btn btn-ghost" @click="$emit('close-modal')">
                <CommonLabel path="BWF/shop/shared/cancel"></CommonLabel>
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import type { CustomerAddress } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import { useVuelidate } from '@vuelidate/core';
import { salutationOptions } from '~/utils/helper/shop/salutations';
import { countryOptions } from '~/utils/helper/shop/countries';
import type { UnwrapNestedRefs } from 'vue';
const props = defineProps<{
    address: CustomerAddress;
    filterShippingCountries?: boolean;
}>();

const { getCountries } = useCountries();
const getActiveCountries = computed(() =>
    props.filterShippingCountries ? getCountries.value.filter(country => country.shippingAvailable) : getCountries.value
);

const selectableCountries = computed(() => countryOptions(getActiveCountries.value));

const emit = defineEmits<{
    (
        e: 'address-submit',
        formsState: UnwrapNestedRefs<{
            zipcode: string | undefined;
            firstName: string;
            lastName: string;
            city: string | undefined;
            street: string | undefined;
            salutationId: string;
            countryId: string | undefined;
            additionalAddressLine1: string;
        }>
    ): void;
    (e: 'close-modal'): void;
}>();

const { getLabels } = useCommonLabels();

const addressFormLabels = await getLabels('/BWF/shop/shared/addressForm/', [
    'identicalToBillingAddress',
    'salutationLabel',
    'chooseSalutationLabel',
    'firstNameLabel',
    'firstNamePlaceholder',
    'lastNameLabel',
    'lastNamePlaceholder',
    'streetAndHouseNoLabel',
    'streetAndHouseNoPlaceholder',
    'postalCodeLabel',
    'postalCodePlaceholder',
    'cityLabel',
    'cityPlaceholder',
    'addressSuffixLabel',
    'addressSuffixPlaceholder',
    'countryLabel',
]);

const addressFormState = reactive({
    firstName: props?.address?.firstName ?? '',
    lastName: props?.address?.lastName ?? '',
    street: !props?.address?.customFields?.dummyAddress ? props.address?.street : '',
    zipcode: !props?.address?.customFields?.dummyAddress ? props.address?.zipcode : '',
    city: !props?.address?.customFields?.dummyAddress ? props.address?.city : '',
    countryId: !props?.address?.customFields?.dummyAddress ? props.address?.countryId : '',
    additionalAddressLine1: props.address?.additionalAddressLine1 ?? '',
    salutationId: props.address?.salutationId ?? '',
});

const {
    firstNameValidators,
    lastNameValidators,
    streetNumberValidators,
    addressAdditionalValidators,
    cityValidators,
    zipCodeValidators,
    requiredValidator,
} = await useValidation();

const rules = computed(() => ({
    firstName: {
        ...requiredValidator,
        ...firstNameValidators,
    },
    lastName: {
        ...requiredValidator,
        ...lastNameValidators,
    },
    street: {
        ...requiredValidator,
        ...streetNumberValidators,
    },
    zipcode: {
        ...requiredValidator,
        ...zipCodeValidators,
    },
    city: {
        ...requiredValidator,
        ...cityValidators,
    },
    additionalAddressLine1: {
        ...addressAdditionalValidators,
    },
    countryId: {
        ...requiredValidator,
    },
}));

const addressFormVuelidate = useVuelidate(rules, addressFormState);

const { getSalutations } = useSalutations();

const invokeSave = async (): Promise<void> => {
    addressFormVuelidate.value.$touch();
    if (await addressFormVuelidate.value.$validate()) {
        emit('address-submit', addressFormState);
    }
};
</script>
