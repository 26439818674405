<template>
    <div class="container grid grid-cols-12 gap-4 my-12">
        <header class="col-span-full flex flex-col gap-2">
            <h3 class="font-medium text-3xl text-dark-blue">
                CommonLabels {{ Object.keys(visibleLabels).length }} /
                {{ Object.keys(COMMON_LABEL_DEFAULTS).length }}
            </h3>
            <h3 class="font-medium text-2xl text-signal-red">MISSING {{ missingLabels.size }}</h3>
            <div class="grid grid-cols-3 gap-4">
                <SelectField
                    v-model="labelState"
                    class="form-field-grey"
                    name="country"
                    label="Label Editing State"
                    placeholder="Label State"
                    required
                    :options="[
                        { value: 'all', label: 'Alle' },
                        { value: 'missing', label: 'Missing' },
                        { value: 'edited', label: 'Edited' },
                    ]"
                ></SelectField>
                <InputField v-model="searchTerm" class="form-field-grey" label="Suche .. "></InputField>
                <CheckboxField v-model="accordionOpen" class="mt-4 text-xs md:text-sm">
                    <span class="font-medium">Open All</span>
                </CheckboxField>
            </div>
        </header>

        <div class="col-span-full flex flex-col gap-2">
            <div v-for="(label, path) in visibleLabels" :key="path" class="rounded p-4 bg-light-grey">
                <AccordionComponent :open="accordionOpen">
                    <template #header>
                        <div class="flex flex-col">
                            <a
                                :href="`${mgnlPublicHost}/.magnolia/admincentral#app:common-label-app:detail;/${path}:edit`"
                                target="_blank"
                                class="font-medium inline-flex gap-4"
                                :class="[isLabelMissing(path) ? 'text-signal-red' : 'text-green']"
                                @click.stop
                            >
                                <span>
                                    {{ path }}
                                </span>

                                <SvgIcon name="Edit" class="h-4 w-4"></SvgIcon>
                            </a>
                            <div class="font-medium">label: {{ label }}</div>
                            <div>default: {{ COMMON_LABEL_DEFAULTS[path] }}</div>
                        </div>
                    </template>
                    <template #content>
                        <div class="py-4 flex flex-col gap-4">
                            <div
                                v-for="pointer in getSourcePointer(path)"
                                :key="pointer.line"
                                class="flex flex-col gap-2"
                            >
                                <span class="font-medium">FILE: {{ pointer.pathToFile }}:{{ pointer.line }}</span>
                                <div class="w-full text-left p-4 bg-white rounded">
                                    {{ pointer.input }}
                                </div>
                            </div>
                        </div>
                    </template>
                </AccordionComponent>
            </div>
        </div>
    </div>
</template>

<script setup>
const { COMMON_LABEL_DEFAULTS } = await import('~/utils/content/CommonLabelDefaults');
const labelJson = await import('~/mgnl/commonLabels.json');
const commonLabels = labelJson.default;
import AccordionComponent from '~/templates/elements/AccordionComponent.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import SelectField from '~/templates/elements/form/SelectField.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';

defineOptions({
    inheritAttrs: false,
});
const {
    public: { mgnlAuthor, mgnlPublicHost },
} = useRuntimeConfig();

const { getLabelByPath } = useCommonLabels();

const missingLabels = ref(new Set([]));
const allLabels = ref({ ...COMMON_LABEL_DEFAULTS });
// initially build maps
await Promise.all(
    Object.entries(allLabels.value).map(async ([path, defaultLabel]) => {
        // preload all labels before rendering to have proper state for each label
        const label = await getLabelByPath(path);
        if (label === defaultLabel) {
            missingLabels.value.add(path);
        } else {
            missingLabels.value.delete(path);
            allLabels.value[path] = label;
        }
    })
);

const isLabelMissing = path => missingLabels.value.has(path);

const visibleLabels = computed(() => {
    const visible = {};
    Object.entries(allLabels.value).forEach(async ([path, labelValue]) => {
        let matchesSearch = true;
        if (searchTerm.value.length > 0) {
            const needle = searchTerm.value.toLowerCase();
            const defaultValue = COMMON_LABEL_DEFAULTS[path].toLowerCase();
            matchesSearch =
                path.toLowerCase().includes(needle) ||
                labelValue.toLowerCase().includes(needle) ||
                defaultValue.includes(needle);
        }
        if (matchesSearch && labelState.value === 'edited' && !missingLabels.value.has(path)) {
            visible[path] = labelValue;
        } else if (matchesSearch && labelState.value === 'missing' && missingLabels.value.has(path)) {
            visible[path] = labelValue;
        } else if (matchesSearch && !['missing', 'edited'].includes(labelState.value)) {
            visible[path] = labelValue;
        }
    });
    return visible;
});

if (!mgnlAuthor) {
    // createError
    throw createError({ statusCode: 404, statusMessage: 'no public route' });
}
const labelState = ref('');
const searchTerm = ref('');
const accordionOpen = ref(false);

const getSourcePointer = path => {
    let pointer = sourcePointer[path] ?? [];
    if (pointer.length === 0) {
        // remove last segment and try again
        const parts = path.split('/');
        const folderPath = parts.join('/');
        pointer = sourcePointer[folderPath];
    }
    return pointer ?? [];
};

const sourcePointer = commonLabels?.matches?.reduce((acc, cur) => {
    const labelPath = cur.labelPath;
    acc[labelPath] = acc[labelPath] || [];
    acc[labelPath].push(cur);
    return acc;
}, {});
</script>
