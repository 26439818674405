<template>
    <div>
        <EditableArea v-if="main" :content="main" />
        <div class="container grid grid-cols-12 my-12 relative">
            <Transition v-if="isLoading" name="loading" mode="out-in">
                <LoadingElement v-if="isLoading" :overlay="true" class="bg-transparent"></LoadingElement>
            </Transition>
            <div v-else class="col-span-full xl:col-span-8 xl:col-start-3 mt-8">
                <div v-if="lookupError" class="notification gap-4 items-start" data-type="danger">
                    <SvgIcon class="notification-icon" name="Error-Circle"></SvgIcon>

                    <div>
                        <div class="font-bold">
                            <CommonLabel path="BWF/shop/orderLookup/errorHeadline"></CommonLabel>
                        </div>
                        <CommonLabel path="BWF/shop/orderLookup/errorText"></CommonLabel>
                    </div>
                </div>
                <template v-else>
                    <OrderDetails
                        v-if="guestOrder?.id && pageView === 'detail-view'"
                        :order="guestOrder"
                        :is-loading="isLoading"
                        @order-action-cancel="cancellationModal.open()"
                        @order-action-service="detailServiceModal.open()"
                        @order-action-return="viewChange('return-view')"
                        @order-action-warranty="viewChange('warranty-view')"
                    ></OrderDetails>

                    <OrderReturnForm
                        v-else-if="guestOrder?.id && pageView === 'return-view'"
                        :is-loading="isLoading"
                        @confirm-modal="returnConfirmModal.open()"
                        @service-modal="returnServiceModal.open()"
                    >
                        <template #back-button>
                            <BackButton class="mb-2 xl:mb-6" custom-click="true" @click="viewChange('detail-view')" />
                        </template>
                    </OrderReturnForm>

                    <OrderWarrantyForm
                        v-else-if="guestOrder?.id && pageView === 'warranty-view'"
                        :warranty-claim-area="warrantyClaimArea"
                        :is-loading="isLoading"
                        @warranty-confirm-modal="warrantyConfirmModal.open()"
                    >
                        <template #back-button>
                            <BackButton class="mb-2 xl:mb-6" custom-click="true" @click="viewChange('detail-view')" />
                        </template>
                    </OrderWarrantyForm>
                </template>
            </div>
        </div>
        <div>
            <EditableArea :content="detailServiceModalArea" :custom-view="EmptyArea" />
            <ModalComponent :content="detailServiceModalArea" :controller="detailServiceModal">
                <template #headline>Leider Nein .. ? *</template>

                <template #primaryAction>
                    <button class="btn btn-blue w-full min-w-fit" @click="detailServiceModal.close()">
                        <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation"></CommonLabel>
                    </button>
                </template>
            </ModalComponent>
            <EditableArea :content="cancellationModalArea" :custom-view="EmptyArea" />
            <ModalComponent :content="cancellationModalArea" :controller="cancellationModal">
                <template #headline>Sicher? *</template>

                <template #primaryAction>
                    <button class="btn btn-blue w-full min-w-fit" @click="onCancelOrder()">
                        <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation"></CommonLabel>
                    </button>
                </template>
                <template #secondaryAction>
                    <button class="btn btn-ghost w-full min-w-fit" @click="cancellationModal.close()">
                        <CommonLabel path="BWF/shop/account/orderDetails/modal/declineCancellation"></CommonLabel>
                    </button>
                </template>
            </ModalComponent>
            <EditableArea :content="returnServiceModalArea" :custom-view="EmptyArea" />
            <ModalComponent :content="returnServiceModalArea" :controller="returnServiceModal">
                <template #headline>Sind sie sich sicher, dass sie zurückgeben wollen..?</template>

                <template #primaryAction>
                    <button class="btn btn-blue w-full min-w-fit" @click="closeAndGoToDetails(returnServiceModal)">
                        <CommonLabel path="BWF/shop/account/orderDetails/modal/confirmCancellation"></CommonLabel>
                    </button>
                </template>
            </ModalComponent>
            <EditableArea :content="returnConfirmModalArea" :custom-view="EmptyArea" />
            <ModalComponent :content="returnConfirmModalArea" :controller="returnConfirmModal">
                <template #headline>Die Rückgabe ist eingegangen *</template>
                <template #richText>
                    Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut.
                </template>
                <template #primaryAction>
                    <button class="btn btn-blue w-full min-w-fit" @click="closeAndGoToDetails(returnConfirmModal)">
                        <CommonLabel path="BWF/shop/shared/back"></CommonLabel>
                    </button>
                </template>
                <template #secondaryAction>
                    <button class="btn btn-ghost w-full min-w-fit" @click="push(marketSetup.startPage)">
                        <CommonLabel path="BWF/shop/account/orderCancel/modal/shopButton"></CommonLabel>
                    </button>
                </template>
            </ModalComponent>
            <EditableArea :content="warrantyClaimArea" :custom-view="EmptyArea"></EditableArea>
            <EditableArea :content="warrantyConfirmModalArea" :custom-view="EmptyArea" />
            <ModalComponent :content="warrantyConfirmModalArea" :controller="warrantyConfirmModal">
                <template #headline>Kundenservice wurde kontaktiert *</template>
                <template #richText>
                    Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut.
                </template>
                <template #primaryAction>
                    <button class="btn btn-blue w-full min-w-fit" @click="closeAndGoToDetails(warrantyConfirmModal)">
                        <CommonLabel path="BWF/shop/shared/back"></CommonLabel>
                    </button>
                </template>
            </ModalComponent>
        </div>
        <EditableArea v-if="bottom" :content="bottom" />
    </div>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useShopwareContext } from '@shopware-pwa/composables-next';
import OrderDetails from '~/templates/components/shop/account/OrderDetails.vue';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import { EditableArea } from '@magnolia/vue-editor';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import { useModal } from '~/composables/useModal';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import BackButton from '~/templates/components/BackButton.vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import { provide } from 'vue';
import OrderReturnForm from '~/templates/components/shop/account/OrderReturnForm.vue';
import OrderWarrantyForm from '~/templates/components/shop/account/OrderWarrantyForm.vue';
import { useGuestOrderDetails } from '~/composables/shop/useGuestOrderDetails';

const { pushInfo, pushError } = useNotifications();

const { isLoggedIn, user } = useUser();
const { apiInstance } = useShopwareContext();
const { marketSetupAdmin, marketSetup } = useMarketSetup();
const { query } = useRoute();

defineProps([
    'main',
    'bottom',
    'cancellationModalArea',
    'returnServiceModalArea',
    'detailServiceModalArea',
    'returnConfirmModalArea',
    'warrantyConfirmModalArea',
    'warrantyClaimArea',
]);

defineOptions({
    inheritAttrs: false,
});

const closeAndGoToDetails = async (controller, view = 'detail-view') => {
    controller.close();
    viewChange(view);
    await loadOrderDetails();
};

const pageView = ref('');
const lookupError = ref(false);
const isLoading = ref(false);

const viewChange = view => {
    pageView.value = view;
};

const userEmail = query.data ?? query.token ?? query.encodedEmail ?? query.email ?? '';
const orderId = query.orderNumber ?? query.orderId;

const { guestOrder, loadGuestOrder } = useGuestOrderDetails();

// need to provide guest order as details here ... otherwise injection chain won't work
provide('swOrderDetails', guestOrder);

const loadOrderDetails = async () => {
    try {
        lookupError.value = false;
        isLoading.value = true;

        await loadGuestOrder(orderId, userEmail);
        pageView.value = 'detail-view';
        isLoading.value = false;
    } catch (e) {
        // for the time being error needs to be catched here as error.value does not get populated. probably malformed custom response
        lookupError.value = true;
        isLoading.value = false;
    }
};

const cancellationModal = useModal();
const detailServiceModal = useModal();
const returnServiceModal = useModal();
const returnConfirmModal = useModal();
const warrantyConfirmModal = useModal();

const onCancelOrder = async () => {
    cancellationModal.close();
    try {
        const {
            data: {
                translated: { name },
            },
        } = await apiInstance.invoke.post('/store-api/order/cancel', {
            order_id: guestOrder?.value?.id,
            customer_email: guestOrder?.value?.orderCustomer?.email,
        });
        pushInfo(name);
        await loadOrderDetails();
    } catch (e) {
        pushError(e?.messages?.[0]?.detail);
    }
};

onMounted(async () => {
    // for some strange reason nuxt throws error if er load / redirect during ssr
    if (userEmail && orderId) {
        // if data is available initially -> load order
        await loadOrderDetails();
        if (guestOrder.value?.id && isLoggedIn.value && user.value?.email === guestOrder.value?.orderCustomer?.email) {
            // user is logged in erdirect to mybrita
            await navigateTo(`${marketSetupAdmin.value?.myBritaOrderDetailsPage}/${guestOrder.value.id}`);
        }
        isLoading.value = false;
    } else {
        lookupError.value = true;
    }
});
</script>

<style scoped>
.loading-enter-active,
.loading-leave-active {
    @apply transition-opacity duration-500;
}

.loading-enter-from,
.loading-leave-to {
    @apply opacity-0;
}
</style>
