<template>
    <component
        :is="tag ?? 'span'"
        :id="isEditMode ? `common-label-${path}` : null"
        class="common-label"
        :class="[
            isEditMode && {
                'underline decoration-wavy': isEditMode,
                'decoration-dark-blue': !isDefaultLabel,
                'decoration-signal-red': !!isDefaultLabel,
            },
        ]"
        @click="isEditMode && onEditClick()"
    >
        <span v-if="containsHtml" v-html="commonLabel"></span>
        <span v-else-if="commonLabel">{{ commonLabel }}</span>
    </component>
</template>

<script setup>
import useCommonLabels from '~/composables/useCommonLabels';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import { replacePattern } from '~/utils/helper/string';

const { marketSetupLinks } = useMarketSetup();

const props = defineProps(['path', 'placeholder', 'forceEditMode', 'tag']);
const isEditMode = computed(() => evaluateEditMode() || props.forceEditMode);

const { getLabelByPath, isLabelDefaultLabel } = useCommonLabels();
const onEditClick = () => {
    let message = `Path: ${props.path}`;
    if (props.placeholder) {
        message += `\nPlaceholder: ${JSON.stringify(props.placeholder)}`;
    }
    alert(message);
};

const rawLabel = props.path ? await getLabelByPath(props.path ?? '') : '';
const isDefaultLabel = isLabelDefaultLabel(rawLabel, props.path);

let commonLabel = computed(() => replacePattern(rawLabel, props.placeholder, marketSetupLinks.value));
let containsHtml = commonLabel?.value && commonLabel?.value?.includes?.('data-html');
</script>
