<template>
    <button @click="product.available ? addToCartProxy() : pushError(addToCartAvailabilityError)">
        <slot>
            <template v-if="product.available">
                <svg-icon
                    name="Checkmark-1"
                    class="text-dark-blue transition-all"
                    :class="[addedToCart ? 'w-4 h-4' : 'w-0 h-0']"
                ></svg-icon>
                <span v-if="addedToCart"> <CommonLabel path="BWF/shop/addToCart/itemAdded"></CommonLabel></span>
                <span v-else><CommonLabel path="BWF/shop/addToCart/addToCart"></CommonLabel></span>
            </template>
            <span v-else><CommonLabel path="BWF/shop/addToCart/notifyMe"></CommonLabel></span>
        </slot>
    </button>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import { useCartInformation } from '~/composables/shop/useCartInformation';
import useItemTracking from '~/composables/tracking/useItemTracking';

const { getLabels } = useCommonLabels();

const { addToCartAvailabilityError, addedToCartPush } = await getLabels('BWF/shop/addToCart', [
    'addedToCartPush',
    'addToCartAvailabilityError',
]);

const props = defineProps(['product']);
const { pushSuccess, pushError } = useNotifications();

const { product } = toRefs(props);

const { addToCart } = useAddToCart(product);
const { count } = useCartInformation();

const addedToCart = ref(false);
const { hasEShopSet, addEShopSetToCart } = await useProductEShopSetConfigurator();

const timeout = null;

const { trackItemAddToCart } = useItemTracking({ item: product });
const addToCartProxy = async () => {
    const beforeCount = count.value;
    const cart = hasEShopSet.value ? await addEShopSetToCart() : await addToCart();

    const entries = Object.entries(cart?.errors ?? {});
    let reachedStock = false;
    if (entries.length > 0) {
        for (const [, value] of entries) {
            pushError(value.message);
            if (!reachedStock) {
                reachedStock = value.messageKey === 'product-stock-reached';
            }
        }
    }
    if (!reachedStock && count.value !== beforeCount) {
        pushSuccess(`${product.value?.translated?.name} ${addedToCartPush}`);
        clearTimeout(timeout);
        addedToCart.value = true;
        setTimeout(() => {
            addedToCart.value = false;
        }, 5000);
        trackItemAddToCart();
    }
};
</script>
