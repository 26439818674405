<template>
    <main class="container grid grid-cols-12">
        <EditableArea v-if="main" class="col-span-full" :content="main" />
        <EditableArea
            v-if="isSuccess || isEditMode"
            class="col-span-full"
            :content="successArea"
            :custom-view="FeedbackArea"
        ></EditableArea>
        <EditableArea
            v-if="!isSuccess || isEditMode"
            :content="unsubscribeArea"
            :custom-view="EmptyArea"
            class="grid col-span-full gap-4 my-12 xl:gap-6 xl:col-start-3 xl:col-end-11 xl:grid-cols-8"
        >
            <HeadlineComponent
                class="font-medium text-dark-blue text-center text-2xl xl:text-4xl xl:col-span-full"
                :headline="unsubscribeArea?.headline"
            ></HeadlineComponent>

            <div
                class="prose prose-black col-span-full mb-6 text-center max-w-full xl:mb-2"
                v-html="unsubscribeArea?.copy"
            ></div>

            <form class="col-span-full xl:col-start-3 xl:col-end-7" @submit.prevent="onSubmit()">
                <div class="mb-6 xl:mb-8">
                    <InputField
                        v-model="formState.email"
                        class="form-field-grey w-full"
                        name="E-Mail"
                        :label="formLabels?.emailLabel"
                        readonly
                    ></InputField>
                </div>
                <div class="prose prose-black mb-6 max-w-full xl:mb-8" v-html="unsubscribeArea?.reasonText"></div>

                <RadioField
                    v-for="reason in selectableReasons"
                    :key="reason.id"
                    v-model="formState.reasonId"
                    class="mb-4"
                    :value="reason.value"
                    name="unsubscribe-reason"
                    @change="showFreetext = reason.value === OTHER_REASON_ID"
                >
                    {{ reason.label }}
                </RadioField>

                <TextAreaField
                    v-if="showFreetext"
                    v-model="formState.freetext"
                    name="message"
                    maxlength="250"
                    :placeholder="formLabels?.reasonMessagePlaceholder"
                ></TextAreaField>

                <button class="btn btn-blue w-full mt-4 xl:mt-6">
                    <CommonLabel path="BWF/components/newsletter/unsubscribe/form/sendButtonLabel"> </CommonLabel>
                </button>
            </form>
        </EditableArea>
        <EditableArea v-if="bottom" class="col-span-full" :content="bottom" />
    </main>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import InputField from '~/templates/elements/form/InputField.vue';
import RadioField from '~/templates/elements/form/RadioField.vue';
import TextAreaField from '~/templates/elements/form/TextAreaField.vue';
import useCommonLabels from '~/composables/useCommonLabels';

import FeedbackArea from '~/templates/areas/FeedbackArea.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import { useRoute } from '#app';
import useBritaMiddleware from '~/composables/useBritaMiddleware';

defineOptions({
    inheritAttrs: false,
});
const props = defineProps(['main', 'unsubscribeArea', 'bottom', 'successArea', 'commonLabel']);
const { getLabels } = useCommonLabels();
const { pushError } = useNotifications();
const { unsubscribe } = useBritaMiddleware();

const showFreetext = ref(false);
const isEditMode = ref(evaluateEditMode());
const isSuccess = ref(false);

const formLabels = await getLabels('BWF/components/newsletter/unsubscribe/form/', [
    'emailLabel',
    'otherReasonLabel',
    'reasonMessagePlaceholder',
    'errorMessage',
]);

const { query } = useRoute();
// if mw is hashing the email, we need the commented line
const unhashedEmail = atob(query.token ?? query.encodedEmail ?? query.email ?? '');
// const unhashedEmail = query.email;

const formState = reactive({
    // query param
    email: unhashedEmail ?? '',
    // query param (myBITA y/n)
    source: query.source ?? 'bwf',
    reasonMessage: '',
    reasonId: '',
    freetext: '',
});

const selectableReasons =
    props?.unsubscribeArea?.commonLabel?.map(({ name, value }) => ({
        value: name,
        label: value,
    })) ?? [];

// add hardcoded "other reason" which toggles freetext
const OTHER_REASON_ID = 'other';
selectableReasons.push({
    value: OTHER_REASON_ID,
    label: formLabels?.otherReasonLabel,
});

watch(formState, () => {
    // copy corresponding label from chosen reason
    formState.reasonMessage = selectableReasons.find(reason => reason.value === formState.reasonId).label;
});

const onSubmit = async () => {
    try {
        await unsubscribe(formState);
        isSuccess.value = true;
    } catch (e) {
        pushError(formLabels?.errorMessage);
    }
};
</script>
