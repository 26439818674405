export default function ({ item }) {
    // provide tracking information and items array
    const { currency } = useSessionContext();
    const gtm = useGtm(); // auto-imported by the module
    const trackingContext = useTrackingContext();
    const { saveItemToStorage, getItemFromStorage, transformItem } = useItemsArray();

    const trackItemAddToCart = () => {
        try {
            const storageItem = getItemFromStorage(item.value);
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.add_to_cart',
                event_name: 'add_to_cart',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value?.isoCode,
                    value: item.value?.calculatedPrice?.totalPrice,
                    items: [storageItem],
                },
            });
        } catch (e) {
            console.error('eec.add_to_cart', e);
        }
    };

    const trackItemView = () => {
        try {
            const storageItem = getItemFromStorage(item.value);
            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.view_item',
                event_name: 'view_item',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value?.isoCode,
                    value: item.value?.calculatedPrice?.totalPrice,
                    items: [storageItem],
                },
            });
        } catch (e) {
            console.error('eec.view_item', e);
        }
    };
    const trackItemSelect = () => {
        try {
            const { items, item_list_id, item_list_name } = trackingContext.value ?? {};

            let trackingProduct = items?.find(arrayItem => arrayItem?.item_variant === item.value.id);
            if (!trackingProduct) {
                // product was not in listing response
                trackingProduct = transformItem(item.value, -1, item_list_name, item_list_id);
            }
            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.select_item',
                event_name: 'select_item',
                ecommerce: {
                    item_list_id,
                    item_list_name,
                    items: [toRaw(trackingProduct)],
                },
            });
            // store item under its
            saveItemToStorage(trackingProduct);
        } catch (e) {
            console.error('eec.select_item', e);
        }
    };

    return { trackItemSelect, trackItemView, trackItemAddToCart };
}
