import { cleanName, getTemplate } from '~/utils/helper/magnolia';

export default function ({ metadata, creativeName, promoName, promoIndex }) {
    let gtm = useGtm(); // auto-imported by the module
    let promotionName = promoName ?? cleanName(getTemplate(metadata)) ?? '';
    let creativeSlot = (promoIndex ?? metadata?.['@index'] ?? '').toString() ?? '0';
    let processedCreativeName = cleanName(creativeName) ?? '';
    const elementIsVisible = ref(false);
    const stopWatching = ref(false);
    const VISIBLE_THRESHOLD = 0.8;

    const trackingActive = inject('trackingActive', ref(true));
    const observeElement = (observedElement, once = false) => {
        try {
            const { stop } = useIntersectionObserver(
                observedElement,
                ([{ isIntersecting, intersectionRatio }]) => {
                    if (isIntersecting && intersectionRatio >= VISIBLE_THRESHOLD) {
                        if (once) {
                            stop();
                        }
                        elementIsVisible.value = true;
                    } else if (!isIntersecting) {
                        elementIsVisible.value = false;
                    }
                },
                { threshold: [0, VISIBLE_THRESHOLD] }
            );
        } catch (e) {
            console.error(e);
        }
    };

    const trackPromoView = observedElement => {
        try {
            watch(
                trackingActive,
                () => {
                    observeElement(observedElement, true);
                },
                { immediate: trackingActive?.value, once: true }
            );
            watch(elementIsVisible, () => trackViewPromotion(), { once: true });
        } catch (e) {
            console.error('gtm-track-promo-view:', e);
        }
    };

    const trackViewPromotionIfVisibleAndActive = (slide, isActive) => {
        observeElement(slide);
        watch(
            isActive,
            () => {
                if (elementIsVisible.value && isActive.value && !stopWatching.value) {
                    stopWatching.value = true;
                    trackViewPromotion();
                }
            },
            { immediate: isActive?.value }
        );
    };

    const trackViewPromotion = () => {
        try {
            // // track custom event for each page view

            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.view_promotion',
                event_name: 'view_promotion',
                event_source: 'source_code',
                ecommerce: {
                    creative_name: processedCreativeName,
                    creative_slot: creativeSlot,
                    promotion_id: '',
                    promotion_name: promotionName,
                },
            });
        } catch (e) {
            console.error('eec.view_promotion', e);
        }
    };

    const trackPromoClick = () => {
        try {
            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.select_promotion',
                event_name: 'select_promotion',
                event_source: 'source_code',
                ecommerce: {
                    creative_name: processedCreativeName,
                    creative_slot: creativeSlot,
                    promotion_id: '',
                    promotion_name: promotionName,
                },
            });
        } catch (e) {
            console.error('eec.select_promotion', e);
        }
    };
    return {
        trackPromoView,
        trackPromoClick,
        trackViewPromotion,
        trackViewPromotionIfVisibleAndActive,
    };
}
