<template>
    <div v-if="slideCount > 0" class="promo-banner flex py-1.5 bg-dark-blue w-full text-white" role="contentinfo">
        <Swiper
            class="container"
            loop
            :modules="[Autoplay]"
            :slides-per-view="1"
            :autoplay="{
                delay: promoBanner?.interval,
                disableOnInteraction: true,
            }"
            :breakpoints="{
                [parseInt(screens.md)]: {
                    slidesPerView: 3,
                },
            }"
            @swiper="onSwiper"
            @breakpoint="onBreakpoint"
        >
            <SwiperSlide v-for="column in columns" :key="getId(column)" class="promo-banner-slide">
                <PromoBannerSlide :column="column" />
            </SwiperSlide>
            <div v-if="canSlide" class="swiper-btn -translate-y-1/2 top-1/2 z-10 left-4 absolute" @click="onClickPrev">
                <SvgIcon name="Arrow-Up" class="-rotate-90 h-5 w-5"></SvgIcon>
            </div>
            <div v-if="canSlide" class="swiper-btn -translate-y-1/2 top-1/2 z-10 right-4 absolute" @click="onClickNext">
                <SvgIcon name="Arrow-Up" class="rotate-90 h-5 w-5"></SvgIcon>
            </div>
        </Swiper>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { mapNodes, getId } from '~/utils/helper/magnolia';
import { Swiper, SwiperSlide } from 'swiper/vue';

import { Autoplay } from 'swiper/modules';
import SvgIcon from '../elements/SvgIcon';
import PromoBannerSlide from '~/templates/elements/PromoBannerSlide.vue';
import { screens } from '~/tailwind.config.screens';
const promoBanner = inject('pagePromoBanner', {});
const columns = computed(() => mapNodes(promoBanner.value?.columns));
const swiper = ref(null);
const slideCount = computed(() => {
    return columns.value?.length;
});
const { isMobile } = useDevice();

const onSwiper = swiperInstance => {
    swiper.value = swiperInstance;
    canSlide.value = !swiperInstance?.isLocked;
};
const canSlide = ref(isMobile.value && slideCount.value > 1);
const onBreakpoint = swiperInstance => {
    canSlide.value = !swiperInstance?.isLocked;
};

const onClickNext = () => {
    swiper?.value?.slideNext();
};
const onClickPrev = () => {
    swiper?.value?.slidePrev();
};
</script>

<style scoped>
.promo-banner-slide {
    @apply flex items-center justify-center;
    @apply w-full md:w-1/3 md:flex-1;
}
</style>
