<template>
    <div>
        <EditableArea :content="main" />

        <div class="container lg:spacing-mt-small">
            <div v-if="isEditMode" class="editmode-info-box break-all">Product ID: {{ sku }}</div>
            <ProductStage v-if="productFound">
                <template v-if="exchangeBoxArea?.introTextAbstract" #introText>
                    <DetailsElement class="mt-6 lg:mt-8">
                        <template #summary>
                            <RichTextComponent :rich-text="exchangeBoxArea?.introTextAbstract" />
                        </template>
                        <template #content>
                            <RichTextComponent :rich-text="exchangeBoxArea?.introTextDetail ?? ''" />
                        </template>
                    </DetailsElement>
                    <div class="border-b mt-4 mb-6 lg:hidden"></div>
                </template>
                <template v-if="exchangeBoxArea?.howToBoxText" #howTo>
                    <div class="notification is-blue gap-4 items-center mt-4 lg:mt-8">
                        <SvgIcon name="Tooltip" class="notification-icon flex-shrink-0" />
                        <span
                            >{{ exchangeBoxArea?.howToBoxText }}&nbsp;<button
                                class="text-link text-black"
                                @click="howToModal.open()"
                            >
                                {{ exchangeBoxArea?.howToModalLink }}
                            </button>
                        </span>
                    </div>
                </template>

                <template #priceInfo>
                    <button
                        class="flex text-icon-link text-xs items-start text-left col-span-full"
                        @click="priceTooltipModal.open()"
                    >
                        <SvgIcon class="h-4 w-4 mr-1.5" name="Info" />
                        <span>{{ exchangeBoxArea?.priceModalLink }}</span>
                    </button>
                </template>
                <template #depositInfo>
                    <button
                        class="flex text-icon-link text-xs items-start text-left col-span-full"
                        @click="depositTooltipModal.open()"
                    >
                        <SvgIcon class="h-4 w-4 mr-1.5" name="Info" />
                        <span>{{ exchangeBoxArea?.depositModalLink }}</span>
                    </button>
                </template>
            </ProductStage>
            <div v-else>No Product found 🫧</div>

            <EditableArea
                :content="exchangeBoxArea"
                :custom-view="EmptyArea"
                class="exchange-box-content space-y-4"
                :class="[{ 'flex flex-col md:grid md:grid-cols-3 gap-2': isEditMode }]"
            >
                <p v-if="isEditMode" class="col-span-full editmode-text font-medium text-2xl text-green">Modals:</p>
                <ModalComponent :controller="howToModal">
                    <template #headline
                        ><HeadlineComponent class="text-lg text-center" :text="exchangeBoxArea?.howToModalHeadline"
                    /></template>
                    <template #richText
                        ><RichTextComponent class="text-center" :rich-text="exchangeBoxArea?.howToModalSubline"
                    /></template>
                </ModalComponent>
                <ModalComponent :controller="depositTooltipModal">
                    <template #headline
                        ><HeadlineComponent class="text-lg text-center" :text="exchangeBoxArea?.depositModalHeadline"
                    /></template>
                    <template #richText
                        ><RichTextComponent class="text-center" :rich-text="exchangeBoxArea?.depositModalSubline"
                    /></template>
                </ModalComponent>
                <ModalComponent :controller="priceTooltipModal">
                    <template #headline>
                        <HeadlineComponent :text="exchangeBoxArea?.priceModalHeadline" class="text-lg text-center"
                    /></template>
                    <template #richText
                        ><RichTextComponent :rich-text="exchangeBoxArea?.priceModalSubline" class="text-center"
                    /></template>
                </ModalComponent>
            </EditableArea>

            <EditableArea :content="technicalSpecsArea" :custom-view="EmptyArea">
                <ProductTechnicalSpecs
                    class="mx-auto md:w-8/12 my-4 xl:my-8"
                    :product="product"
                    :visible-specs="technicalSpecsArea?.visibleSpecs"
                    :downloads="technicalSpecsArea?.downloads"
                    :content="technicalSpecsArea"
                />
            </EditableArea>
        </div>
        <EditableArea v-if="bottom" :content="bottom" />
    </div>
</template>
<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { useProductSearch } from '@shopware-pwa/composables-next';
import useMagnoliaContent from '~/composables/useMagnoliaContent';
import ProductTechnicalSpecs from '~/templates/components/shop/product/ProductTechnicalSpecs.vue';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import RichTextComponent from '~/templates/components/cms/RichTextComponent.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import DetailsElement from '~/templates/elements/DetailsElement.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';

defineOptions({
    inheritAttrs: false,
});

import useD2CStructuredData from '~/composables/useD2CStructuredData';
import { getMainImageUrl, getTranslatedProperty } from '@shopware-pwa/helpers-next';
import { containsImage, imageResizerPath } from '~/utils/helper/magnolia';
import { PRODUCT_DETAILS_ASSOCIATIONS } from '~/utils/helper/shop/product';

const isEditMode = evaluateEditMode();

const howToModal = useModal();
const depositTooltipModal = useModal();
const priceTooltipModal = useModal();

const props = defineProps([
    'navigationId',
    'title',
    'description',
    'ogImage',
    'ogTitle',
    'ogType',
    'metadata',
    'main',
    'bottom',
    'sku',
    'technicalSpecsArea',
    'exchangeBoxArea',
]);

let productId = toRaw(props.sku);
const { pathVariable } = useMagnoliaContent();

if (pathVariable.value) {
    // assume variable and overwrite configured sku to load a variant
    const { resolvePath } = useNavigationSearch();
    const { data: seoResult } = await useAsyncData('cmsResponse' + pathVariable.value, async () => {
        return await resolvePath(pathVariable.value);
    });

    if (seoResult.value) {
        // means a proper formed product link was clicked
        productId = useNavigationContext(seoResult)?.foreignKey?.value;
    } else {
        // assume that pathvariable is the product id
        productId = pathVariable.value.replace('/', '');
    }
}

const productFound = ref(false);
const product = ref();
const configurator = ref();
const isLoading = ref(true);

isLoading.value = true;
const { search } = useProductSearch();
try {
    const { data: productResponse } = await useAsyncData('product-detail-' + productId, async () => {
        return await search(productId, PRODUCT_DETAILS_ASSOCIATIONS);
    });

    useProduct(productResponse.value?.product, productResponse.value?.configurator);

    productFound.value = true;
    product.value = productResponse.value?.product;
    configurator.value = productResponse.value?.configurator;
    isLoading.value = false;
} catch (e) {
    if (!isEditMode) {
        showError({ statusCode: 404, statusMessage: 'not found' });
    }
}
// update seo data

const { marketSetup } = useMarketSetup();
const metaDescription =
    props.description ||
    getTranslatedProperty(product.value, 'metaDescription') ||
    marketSetup.value?.fallbackDescription;

const title =
    props.title ||
    getTranslatedProperty(product.value, 'metaTitle') ||
    getTranslatedProperty(product.value, 'name') ||
    marketSetup.value?.fallbackTitle;
const ogTitle = props.ogTitle || title;

const mainImg = containsImage(props.ogImage)
    ? imageResizerPath(props?.ogImage, 1200, 630)
    : getMainImageUrl(product.value);

useHead({
    title,
    meta: [
        ...(metaDescription ? [{ name: 'description', content: metaDescription }] : []),
        ...(metaDescription ? [{ name: 'og:description', content: metaDescription }] : []),
        ...(ogTitle ? [{ name: 'og:title', content: ogTitle }] : []),
        ...(mainImg ? [{ name: 'og:image', content: mainImg }] : []),
    ],
});

const { productSchema } = useD2CStructuredData();
useJsonld(productSchema(product?.value, configurator?.value));
</script>
