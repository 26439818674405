import { getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';

const THIRTY_MINUTES_MS = 30 * 60 * 1000; // 30 minutes in milliseconds
export default function () {
    // provide tracking information and items array
    const { currency, selectedShippingMethod, selectedPaymentMethod } = useSessionContext();
    const gtm = useGtm(); // auto-imported by the module
    const { getOrderItemFromStorage, getItemFromStorage } = useItemsArray();
    const { cartItems, cart, appliedPromotionCodes } = useCart();
    const { user, isGuestSession } = useUser();

    const cartItemsArray = computed(() => {
        const items = getLineItemsWithoutPromotion(cartItems.value);
        // overwrite index from storage with cart index
        return items.map((item, index) => ({
            ...getItemFromStorage(item),
            quantity: item.quantity,
            index,
        }));
    });

    const trackItemRemoveFromCart = item => {
        try {
            const removedItem = cartItemsArray.value?.find(cartItem => cartItem?.['item_id'] === item.value.id);
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.remove_from_cart',
                event_name: 'remove_from_cart',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value?.isoCode,
                    value: item.value?.price?.totalPrice,
                    items: [removedItem],
                },
            });
        } catch (e) {
            console.error('eec.remove_from_cart', e);
        }
    };

    const trackCartView = () => {
        try {
            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.view_cart',
                event_name: 'view_cart',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value.isoCode,
                    value: cart.value.price.totalPrice,
                    coupon: appliedPromotionCodes.value?.[0]?.description,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.view_cart', e);
        }
    };
    const trackBeginCheckout = () => {
        try {
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.begin_checkout',
                event_name: 'begin_checkout',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value.isoCode,
                    value: cart.value.price.totalPrice,
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.begin_checkout', e);
        }
    };

    const trackShippingInfo = () => {
        try {
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.add_shipping_info',
                event_name: 'add_shipping_info',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value.isoCode,
                    value: cart.value.price.totalPrice,
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    shipping_tier: selectedShippingMethod.value?.name,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.add_shipping_info', e);
        }
    };

    const trackPaymentInfo = () => {
        try {
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.add_payment_info',
                event_name: 'add_payment_info',
                event_source: 'source_code',
                ecommerce: {
                    currency: currency.value.isoCode,
                    value: cart.value.price.totalPrice,
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    payment_type: selectedPaymentMethod.value?.name,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.add_payment_info', e);
        }
    };
    const trackPurchase = order => {
        try {
            gtm?.trackEvent({ ecommerce: null });

            const userCreationDate = new Date(user.value.createdAt);
            const isNewCustomer = Date.now() - userCreationDate <= THIRTY_MINUTES_MS;
            const coupon = order.customFields?.promotions?.map(promo => promo.code).join(',') ?? '';
            const items = order.lineItems.map((item, index) => ({
                ...getOrderItemFromStorage(item),
                quantity: item.quantity,
                index,
            }));
            const checkout_type = isGuestSession.value
                ? 'as_guest'
                : isNewCustomer
                  ? 'as_new_customer'
                  : 'as_existing_customer';

            gtm?.trackEvent({
                event: 'eec.purchase',
                event_name: 'purchase',
                event_source: 'source_code',
                checkout_type,
                ecommerce: {
                    transaction_id: order.id,
                    currency: order.currency.isoCode,
                    value: order.amountTotal,
                    tax: order.amountTotal - order.amountNet,
                    shipping: order.shippingTotal,
                    coupon: coupon,
                    items,
                },
            });
        } catch (e) {
            console.error('eec.purchase', e);
        }
    };

    return {
        trackCartView,
        trackBeginCheckout,
        trackShippingInfo,
        trackPaymentInfo,
        trackPurchase,
        trackItemRemoveFromCart,
    };
}
