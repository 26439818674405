const ITEM_PREFIX = 'GA_ITEM';
export default function () {
    const { currency } = useSessionContext();
    /**
     * serializes shopware "line items" to an array of tracking compatible items
     */
    // TODO build a generic assignment for Shopware Properties to generate property key-value pairs to add into transformed item (TBD)
    const transformItem = (lineItem, index, item_list_name = '', item_list_id = '') => ({
        item_name: lineItem.translated?.name ?? lineItem.name ?? lineItem.label,
        item_id: lineItem.parentId ?? lineItem.id,
        item_variant: lineItem.id,
        price: lineItem.calculatedPrice?.totalPrice ?? lineItem?.price?.totalPrice ?? lineItem?.totalPrice,
        currency: currency.value?.isoCode,
        ...(lineItem.manufacturer?.translated?.name ? { item_brand: lineItem.manufacturer?.translated?.name } : {}),
        ...(lineItem.categories?.[0] ? { item_category: lineItem.categories?.[0]?.name } : {}),
        ...(lineItem.categories?.[1] ? { item_category2: lineItem.categories?.[1]?.name } : {}),
        ...(lineItem.categories?.[2] ? { item_category3: lineItem.categories?.[2]?.name } : {}),
        ...(lineItem.categories?.[3] ? { item_category4: lineItem.categories?.[3]?.name } : {}),
        ...(lineItem.categories?.[4] ? { item_category5: lineItem.categories?.[4]?.name } : {}),
        item_color:
            lineItem.options?.find(option => option.group.displayType.includes('color'))?.name ?? 'not available',
        item_size:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_size')
            )?.name ?? 'not available',
        item_amount:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_amount')
            )?.name ?? 'not available',
        item_filter:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_filter')
            )?.name ?? 'not available',
        item_armature:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_armature')
            )?.name ?? 'not available',
        item_accessories1:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_accessories1')
            )?.name ?? 'not available',
        item_subscription:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_subscription')
            )?.name ?? 'not available',
        item_flavour:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_flavour')
            )?.name ?? 'not available',
        item_material:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_material')
            )?.name ?? 'not available',
        item_cylinder:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_cylinder')
            )?.name ?? 'not available',
        index,
        quantity: lineItem?.quantity ?? 1,
        item_list_name,
        item_list_id,
    });

    const transformOrderItem = (lineItem, index, item_list_name = '', item_list_id = '') => ({
        item_name: lineItem?.translated?.name ?? lineItem?.name ?? lineItem?.label,
        item_id: lineItem.item_id ?? lineItem.id,
        item_variant: lineItem.item_id ?? lineItem.id,
        price: lineItem.calculatedPrice?.totalPrice ?? lineItem?.price?.totalPrice ?? lineItem?.totalPrice,
        currency: currency.value?.isoCode,
        index,
        quantity: lineItem?.quantity ?? 1,
        item_list_name,
        item_list_id,
    });

    const getItemsArray = (lineItems = [], item_list_name = '', item_list_id = '') => {
        try {
            return (
                lineItems?.map((lineItem, index) => transformItem(lineItem, index, item_list_name, item_list_id)) ?? []
            );
        } catch (e) {
            console.error('eec.getItemsArray', e);
            return [];
        }
    };
    const saveItemToStorage = item => {
        // item variant is most specigfic and always sw id
        useSessionStorage(`${ITEM_PREFIX}_${item.item_variant}`, item);
    };

    const getItemFromStorage = item => {
        const storageItem = useSessionStorage(`${ITEM_PREFIX}_${item.id}`).value;

        if (storageItem) {
            // return item with list reference
            return JSON.parse(storageItem);
        } else {
            // serialize new item
            const transformedItem = transformItem(item, -1);
            saveItemToStorage(transformedItem);
            return transformedItem;
        }
    };

    const getOrderItemFromStorage = item => {
        const storageItem = useSessionStorage(`${ITEM_PREFIX}_${item.productId}`).value;
        if (storageItem) {
            // return item with list reference
            return JSON.parse(storageItem);
        } else {
            // serialize new item
            return transformOrderItem(item, -1);
        }
    };

    return { getItemsArray, saveItemToStorage, getItemFromStorage, transformItem, getOrderItemFromStorage };
}
