export default function () {
    try {
        const gtm = useGtm(); // auto-imported by the module
        gtm?.trackEvent({
            event: 'gx.newsletter_soi',
            event_name: 'newsletter_soi',
            event_source: 'source_code',
            newsletter_soi: {
                newsletter_selection: 'brita newsletter',
            },
        });
    } catch (e) {
        console.error(e);
    }
}
