<template>
    <div class="grid xl:grid-cols-3">
        <div class="w-full xl:hidden">
            <button class="text-icon-link" @click="$router.go(-1)">
                <SvgIcon name="Arrow-Up" class="h-3 w-3 -rotate-90"></SvgIcon>
                <CommonLabel path="BWF/shop/shared/backToShop" />
            </button>

            <hr class="mt-4 mb-6 xl:my-12" />
        </div>
        <div class="flex flex-col xl:w-1/2 mx-auto xl:col-span-2">
            <CheckInComponent />

            <hr class="my-6 xl:my-12" />
            <div>
                <h3 class="mb-1 font-medium text-lg md:mb-4 md:text-2xl">
                    <CommonLabel path="BWF/shop/checkout/mybrita/guestProceed" />
                </h3>
                <div v-if="containsExchangeBoxProduct(cartItems)" class="notification" data-type="danger">
                    <SvgIcon name="Error-Circle" class="notification-icon"></SvgIcon>
                    <div>
                        <CommonLabel
                            tag="div"
                            class="font-medium"
                            path="BWF/shop/checkout/mybrita/myBritaOnlyHeadline"
                        ></CommonLabel>
                        <CommonLabel path="BWF/shop/checkout/mybrita/myBritaOnlyDescription"></CommonLabel>
                    </div>
                </div>
                <template v-else>
                    <div class="mb-4 text-sm">
                        <CommonLabel path="BWF/shop/checkout/mybrita/notReadyToRegister" />
                    </div>
                    <button class="btn btn-ghost w-full" @click="$emit('guest')">
                        <CommonLabel path="BWF/shop/checkout/cart/toCheckout" />
                    </button>
                </template>
            </div>
            <div class="mt-14"><CommonLabel path="BWF/shared/vatFootnote"></CommonLabel></div>
        </div>
        <div v-if="isDesktop" class="grid gap-8">
            <CheckoutSummary />
            <div class="grid gap-4">
                <div class="flex items-end justify-between">
                    <h3 class="text-2xl font-medium"><CommonLabel path="BWF/shop/shared/cartHeadline" /></h3>

                    <div v-if="cartItems.length > 0" class="text-sm">
                        <CommonLabel class="font-medium" path="BWF/shop/shared/tableQuantity" />&nbsp;{{ count }}
                    </div>
                </div>
                <ul role="list" class="-my-4 divide-y divide-gray-200 pl-0">
                    <li
                        v-for="cartItem in getLineItemsWithoutPromotion(cartItems)"
                        :key="cartItem.id"
                        class="flex py-6"
                    >
                        <CheckoutCartItem :cart-item="cartItem" :simple-variant="true" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CommonLabel from '~/templates/components/CommonLabel.vue';
import CheckInComponent from '~/templates/components/shop/account/CheckInComponent.vue';
import { containsExchangeBoxProduct, getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';
import { useCartInformation } from '~/composables/shop/useCartInformation';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
const { count } = useCartInformation();

const emit = defineEmits<{
    (e: 'guest'): void;
    (e: 'loadingStart'): void;
    (e: 'loadingEnd'): void;
}>();

const { cartItems } = useCart();
const { isDesktop } = useDevice();

onMounted(() => {
    emit('loadingEnd');
});
</script>
